
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { MatDialog } from '@angular/material/dialog';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MediaService } from 'src/app/services/media/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContainerPasswordService } from 'src/app/services/container-password/container-password.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';

@Component({
  selector: 'app-container-content',
  templateUrl: './container-content.component.html',
  styleUrls: ['./container-content.component.scss']
})
export class ContainerContentComponent implements OnInit{

  @ViewChild('editor') editor: any;
  @ViewChild('start') start: any;
  @ViewChild('deleteItemDialog') deleteItemDialog: any;

  index: any;
  type: any;
  container: any;
  userPlan: any;
  preview: any;

  isSmallScreen: boolean = false;
  isFirstPage: boolean = true;
  newNote: boolean = false;
  newFile: boolean = false;

  isAddPassword: boolean = false;
  show: boolean = false;
  disabled: boolean = false;
  disabledButton:boolean = false;
  newAccess: boolean = true;
  activeItem = -1;
  icon = 1;
  disableEdit: boolean = true;
  searchString = '';
  newComment = '';
  notesData = '';
  comment = '';
  fileName = '';
  name = '';
  userName = '';
  password = '';
  url = '';
  notes = {data: '', deltaJson: []};
  files = [];
  filesToShow = [];
  comments = [];
  editC = [];
  scale = {'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000};
  user: any = {firstName: '', lastName: '', profilePicture: '' , email: '', id: 0};
  modules= {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'size': ['small', 'large'] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['link']   
    ]
  }

  get dark(){
      return this.theme.dark;
  }

  get ownContainers(): any{
      return this.containerService.ownContainers;
  }

  get sharedContainers(): any{
      return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any{
      return this.containerService.deadManSwitchContainers;
  } 

  constructor(public dialog: MatDialog, private theme: ThemeService, private location: Location, breakpointObserver: BreakpointObserver, private route: ActivatedRoute, private localstorage: LocalStorageService, private media: MediaService, private sanitizer: DomSanitizer, private _snackBar: MatSnackBar, private containerPassword: ContainerPasswordService, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private cdr: ChangeDetectorRef, private dexieService: DexieService) {
    breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isSmallScreen = true;
        } else {
          this.isSmallScreen = false;
        }
      });
      this.userPlan = {...JSON.parse(this.localstorage.getPlan()), memory: {...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory)}};
      this.user =  {firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email:  JSON.parse(this.localstorage.getUser())['email'], id: JSON.parse(this.localstorage.getUser())['id'] };
      this.type = this.route.snapshot.paramMap.get('type');
      this.index = this.route.snapshot.paramMap.get('index');
      this.setData();
  }

  ngOnInit(){
    this.setData();
  }
  
  setData(){
    if(this.type=='own'){
      this.container = this.ownContainers[this.index];
    }else if(this.type=='shared'){
      this.container = this.sharedContainers[this.index];
    }else{
      this.container = this.deadManSwitchContainers[this.index];
    }
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  onFileChange(e: any){  
    const extensions = ['.exe', '.bat', '.cmd', '.sh', '.msi', '.dll', '.js', '.php', '.asp', '.aspx', '.py', '.rb', '.java', '.html', '.htm', '.bmp', '.svg', '.log', '.db', 'sqlite', '.xml', '.json', '.env', '.mp3', '.dmg', '.webp', '.webm'];
    let file = e.target.files[0];
    if(file){
      if(file.size>8000000){
        this.openSnackBar('File should be at most 8 MB!');

      }else{
        let fileName = file.name;
        let dotIndex = fileName.indexOf('.');
        let name = fileName.slice(0, dotIndex);
        let extension = fileName.slice(dotIndex);
        if(extensions.includes(extension)){
          this.openSnackBar(`You canno't upload a ${extension} file. Please upload another file type!`);
        }else{
          this.media.getIconByName(extension)
          .subscribe({
            next: (res: any)=>{
              let icon = res.icon;
              const formData = new FormData();
              formData.append("file", file);
              this.containerPassword.getFileData(formData)
                .subscribe({
                    next: (res: any)=>{
                      this.files.push({ name, icon:{id: icon.id, data: icon.data}, size: file.size, data: res.data, extension, type: file.type});
                      this.filesToShow.push({name, extension, icon: icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(res.data).data)], { type: file.type }))) })
                      },
                  error: (error: HttpErrorResponse)=>{
                  }
                });
            },
            error: (error: HttpErrorResponse)=>{
            }
          });
        }
      }
    }    
  }

  onIconChange(event: any){
    const file:File = event.target.files[0];
    const fileTypes = [ "image/png", "image/jpg", "image/jpeg", "image/ico", "image/svg+xml" ];

    const validFileType = (type) => fileTypes.includes(type);

    const validSize = (size) => size < 10000

    if (file) {
      if(!validFileType(file.type)) return this.openSnackBar('You need to upload an image: .png, .jpg, .jpeg, .svg ,or .ico file!');

      if(!validSize(file.size)) return this.openSnackBar('You need to upload an image with a size at most 10 KB!');
      
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("icon", file);
      this.media.saveIcon(formData)
          .subscribe({
            next: (res: any)=>{
              this.icon = res.id;
              let my_data = new Uint8Array(JSON.parse(res.data).data);
              let string_char = my_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
              let base64String = btoa(string_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:'+ res.type + ';base64,' + base64String);
              this.openSnackBar('File uploaded successfully!');
            },
            error: (error: HttpErrorResponse)=>{
              this.openSnackBar('Cannot save icon!');
            }
          });
    }
  }

  extractFavicon(){
    if(!this.disableEdit){
      if(this.url===''){
        this.openSnackBar('You need to enter an url first!');
      }else{
        this.containerPassword.extractFavicon(this.url)
        .subscribe({
          next: (res: any)=>{
            if(res.faviconUrl.includes('https://www.google.com/s2/favicons?sz=64&domain_url=') || res.faviconUrl.includes('assets/images/predefined-icons')){
              this.preview = res.faviconUrl;
            }else{
              let icon_data = new Uint8Array(JSON.parse(res.faviconUrl).data);
              let string_icon_char = icon_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
              let iconBase64String = btoa(string_icon_char);
              this.preview = this.sanitizer.bypassSecurityTrustUrl('data:'+ 'image/svg+xml' + ';base64,' + iconBase64String)['changingThisBreaksApplicationSecurity'];
            }
            this.icon = res.id;
            this.openSnackBar(res.message);
          },
          error: (error: HttpErrorResponse)=>{
            this.openSnackBar('Favicon cannot be extracted!');
          }
        });
      }
    }
  }

  calculateMemory(dataToSave: any, icon: any, data: any){
    let memory = Buffer.byteLength(JSON.stringify({...dataToSave, icon: icon, files: []}));
    data.files.forEach((file: any) => { memory = memory + file.size });
    let size = {};

    if(memory<999){
      size = {memory, unit: 'B'};

    }else if((memory>=1000) && (999999>memory)){
      size = {memory: (memory/1000), unit: 'KB'};

    }else if((memory>=1000000) && (999999999>memory)){
      size = {memory: (memory/1000000), unit: 'MB'};

    }else if((memory>=1000000000) && (999999999999>memory)){
      size = {memory: (memory/1000000000), unit: 'GB'};

    }else if((memory>=1000000000000) && (999999999999999>memory)){
      size = {memory: (memory/1000000000000), unit: 'TB'};

    } 

    const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    return {size, restStorage}
  }

  toggleShow(){
    this.show = !this.show;
  }

  addPassword(){
    this.disabled = false;
    this.isAddPassword = true;
    this.activeItem = -1;
    this.comment = '';
    this.newNote = false;
    this.newFile = false;
    this.name = '';
    this.userName = '';
    this.password = '';
    this.url = '';
    this.preview = 'assets/images/dashboard/container-content/photo.svg';
    this.comments = [];
    this.editC = [];
    this.files = [];
    this.icon = 1;
    this.notes = {data: '', deltaJson: []};
    this.isFirstPage = false;
    this.disabledButton = false;
    this.disableEdit = false;
    this.notesData = '';
  }
  
  addNewAccess() {
    this.newAccess = !this.newAccess; 
  }

  addNewNote() {
    this.newNote = !this.newNote; 
  }

  addNewFile() {
    this.newFile = !this.newFile; 
  }

  toggleSidebar(){
    this.start.toggle();
  }

  back(){
    this.location.back()
  }

  goBack(){
    this.isFirstPage = true;
  }

  openAccount(index: any){
    this.setData();
    window.location.href = this.container?.decryptedPasswords[index].url;
  }

  selectItem(index: any){
    this.setData();
    this.activeItem = index;
    this.comment = '';
    this.isAddPassword = false;
    this.isFirstPage = false;
    this.newNote = !!this.container?.decryptedPasswords[this.activeItem].notes && this.container?.decryptedPasswords[this.activeItem].notes.data?.length>0;
    this.newFile = !!this.container?.decryptedPasswords[this.activeItem].files && this.container?.decryptedPasswords[this.activeItem].files.length>0;
    this.name = this.container?.decryptedPasswords[this.activeItem].name;
    this.userName = this.container?.decryptedPasswords[this.activeItem].userName;
    this.password = this.container?.decryptedPasswords[this.activeItem].password;
    this.url = this.container?.decryptedPasswords[this.activeItem].url;
    this.preview = this.container?.decryptedPasswords[this.activeItem].icon.data;
    this.icon = this.container?.decryptedPasswords[this.activeItem].icon.id;
    this.comments = this.container?.decryptedPasswords[this.activeItem].comments ?? [];
    this.editC = this.comments.map((e)=>false);
    this.files = this.container?.decryptedPasswords[this.activeItem].files ?? [];
    this.filesToShow = this.files.map((file)=> { return {name: file.name, extension: file.extension, icon: file.icon.data, data: this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(new Blob([new Uint8Array(JSON.parse(file.data).data)], { type: file.type }))) }});
    this.notes = this.container?.decryptedPasswords[this.activeItem].notes ?? {data: '', deltaJson: []};
    this.notesData = this.container?.decryptedPasswords[this.activeItem].notes?.data ?? '';
    this.disabled = this.user.id!==this.container?.decryptedPasswords[index].owner;
    this.disabledButton = false;
    this.disableEdit = true;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  onContentChanged($event: any){
    this.notes.deltaJson = $event.content['ops'];
    this.notes.data = this.notesData;
  }

  timeDifference(date: any) {
    let time_difference = new Date().getTime()-new Date(date).getTime();
    if(time_difference<1000){
    return `${time_difference} milliseconds ago`;
    }else if((time_difference/1000)<60){
    return `${Math.floor(time_difference/1000)} seconds ago`;
    }else if((time_difference/60000)<60){
    return `${Math.floor(time_difference/60000)} minutes ago`;
    }else if((time_difference/3600000)<24){
    return `${Math.floor(time_difference/3600000)} hours ago`;
    }else if((time_difference/86400000)<7){
    return `${Math.floor(time_difference/86400000)} days ago`;
    }else if((time_difference/604800000)<4){
    return `${Math.floor(time_difference/604800000)} weeks ago`;
    }else{
    return new Date(date).toISOString().split('T')[0];
    }
  }

  cancel(){
    this.activeItem = -1;
    this.comment = '';
    this.isAddPassword = false;
    this.disabledButton = false;
  }

  addComment(){
    this.comments.push({owner: this.user, comment: this.comment, createdAt: new Date()});
    this.editC.push(false);
    this.comment = '';
    if(!this.isAddPassword && this.disableEdit) this.savePasswordAfterEdit();
  }

  editComment(index: any){
    this.editC[index] = true;
    this.newComment = this.comments[index].comment;
  }

  saveEditComment(index: any){
    this.comments[index].comment = this.newComment;
    this.editC[index]= false;
    if(!this.isAddPassword && this.disableEdit) this.savePasswordAfterEdit();
  }

  cancelEditComment(){
    this.editC = this.editC.map((e)=> false);
  }

  deleteComment(index: any){
    this.comments.splice(index, 1);
    this.editC.splice(index, 1);
    if(!this.isAddPassword && this.disableEdit) this.savePasswordAfterEdit();
  }

  savePasswordAfterEdit(){
    this.setData();
    this.disabledButton = true;
    let data = {comments: this.comments, files: this.newFile ? this.files: [], notes: this.newNote ? this.notes: {data: '', deltaJson: []}};
    this.media.getIcon(this.icon)
        .subscribe({
          next: async (res: any)=>{
              let iconData: any;
              if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                iconData = res.icon;
              }else{
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              let passEncryption = '';
              let dataEncryption = '';

              if(this.type=='shared') {
                  passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedRecipientKey);
                  dataEncryption =  await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedRecipientKey)
              }else if(this.type=='own'){
                  passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedOwnerKey);
                  dataEncryption =  await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedOwnerKey)
              }else{
                  passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedBackUpPersonKey);
                  dataEncryption =  await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedBackUpPersonKey)
              }
              let dataToSave = { url: this.url, name: this.name, icon: this.icon, userName: this.userName, password: passEncryption, passData: dataEncryption};
              const { size, restStorage } = this.calculateMemory(dataToSave, res.icon, data);

              if(restStorage > 0 ){
                this.containerPassword.updatePassword(dataToSave, size, this.container.decryptedPasswords[this.activeItem].id, containerID)
                  .subscribe({
                    next: async (result: any)=>{
                      if(this.type=='own'){
                          this.dexieService.getOwnContainers().then((dt: any)=>{
                              let d = dt;
                              let decrpass =  this.container.decryptedPasswords;
                              decrpass[this.activeItem]= {...decrpass[this.activeItem], url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, size, ...data};
                              let newMemory = JSON.parse(result.newMemory)
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, decryptedPasswords: decrpass};
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              this.successEdit();
                          });

                      }else if(this.type=='shared'){
                          this.dexieService.getSharedContainers().then((dt: any)=>{
                            let d = dt;
                            let decrpass =  this.container.decryptedPasswords;
                            decrpass[this.activeItem]= {...decrpass[this.activeItem], url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, size, ...data};
                            let newMemory = JSON.parse(result.newMemory)
                            d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, decryptedPasswords: decrpass};
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            this.successEdit();
                          });
                      }else{
                          this.dexieService.getDeadManSwitchContainers().then((dt: any)=>{
                            let d = dt;
                            let decrpass =  this.container.decryptedPasswords;
                            decrpass[this.activeItem]= {...decrpass[this.activeItem], url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, size, ...data};
                            let newMemory = JSON.parse(result.newMemory)
                            d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, decryptedPasswords: decrpass};
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            this.successEdit();
                          });
                      }
                      this.disabledButton = false;
                    }, 
                    error: (error: HttpErrorResponse)=>{
                      this.disabledButton = false;
                      this.openSnackBar('Error while updating the password!');
                    }
                  });
        
                } else{
                  this.disabledButton = false;
                  this.openSnackBar('Password cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
                }
                this.disabledButton = false;
        },
        error: (error: HttpErrorResponse)=>{
          this.disabledButton = false;
        }
      });
  }

  editItem(){
    this.disableEdit = !this.disableEdit;
  }

  deleteItem(){
    this.dialog.open(this.deleteItemDialog, {width: '400px'});
  }

  cancelDeleteItem(){
    this.dialog.closeAll();
  }

  removeFile(index: any){
    this.files.splice(index, 1);
    this.filesToShow.splice(index, 1);
  }

  moveToTrash(){
    this.setData();
    this.containerPassword.moveToTrashPassword(this.container.decryptedPasswords[this.activeItem].id, this.container.id)
    .subscribe({
      next: (res: any)=>{
        this.disabledButton = true;
        if(this.type=='own'){
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              this.container.passwords.splice(this.activeItem, 1);
              this.container.decryptedPasswords.splice(this.activeItem, 1);
              d[this.index] = this.container;
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
              this.successDelete();
            });

        }else if(this.type=='shared'){
          this.dexieService.getSharedContainers().then((data: any)=>{
            let d = data;
            this.container.passwords.splice(this.activeItem, 1);
            this.container.decryptedPasswords.splice(this.activeItem, 1);
            d[this.index] = this.container;
            this.dexieService.setSharedContainers(d);
            this.containerService.setSharedContainers(d);
            this.successDelete();
          });
        }else{
          this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
            let d = data;
            this.container.passwords.splice(this.activeItem, 1);
            this.container.decryptedPasswords.splice(this.activeItem, 1);
            d[this.index] = this.container;
            this.dexieService.setDeadManSwitchContainers(d);
            this.containerService.setDeadManSwitchContainers(d);
            this.successDelete();
          });
        }
      },
      error: (error: HttpErrorResponse)=>{
        this.dialog.closeAll();
        this.disabledButton = false;
        this.openSnackBar('Cannot delete password!');
      }
    });
  }

 delete(){
    this.setData();
    this.containerPassword.deletePassword(this.container.decryptedPasswords[this.activeItem].id, this.container.id)
    .subscribe({
      next: (res: any)=>{
        this.disabledButton = true;
        if(this.type=='own'){
            this.dexieService.getOwnContainers().then((data: any)=>{
              let d = data;
              this.container.passwords.splice(this.activeItem, 1);
              this.container.decryptedPasswords.splice(this.activeItem, 1);
              let newMemory = JSON.parse(res.newMemory);
              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}};
              this.dexieService.setOwnContainers(d);
              this.containerService.setOwnContainers(d);
              this.successDelete();
            });

        }else if(this.type=='shared'){
          this.dexieService.getSharedContainers().then((data: any)=>{
            let d = data;
            this.container.passwords.splice(this.activeItem, 1);
            this.container.decryptedPasswords.splice(this.activeItem, 1);
            let newMemory = JSON.parse(res.newMemory);
            d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}};
            this.dexieService.setSharedContainers(d);
            this.containerService.setSharedContainers(d);
            this.successDelete();
          });
        }else{
          this.dexieService.getDeadManSwitchContainers().then((data: any)=>{
            let d = data;
            this.container.passwords.splice(this.activeItem, 1);
            this.container.decryptedPasswords.splice(this.activeItem, 1);
            let newMemory = JSON.parse(res.newMemory);
            d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}};
            this.dexieService.setDeadManSwitchContainers(d);
            this.containerService.setDeadManSwitchContainers(d);
            this.successDelete();
          });
        }
      },
      error: (error: HttpErrorResponse)=>{
        this.dialog.closeAll();
        this.disabledButton = false;
        this.openSnackBar('Cannot delete password!');
      }
    });
  }


  save(){
    if(this.name.length==0) this.openSnackBar('Name is required!');
    else if(this.userName.length==0) this.openSnackBar('Username is required!');
    else if(this.password.length==0) this.openSnackBar('Password is required!');
    else{
      this.savePasswordAfterEdit();
    }
    
  }

  add(){
    this.setData();
    if(this.name.length==0) this.openSnackBar('Name is required!');
    else if(this.userName.length==0) this.openSnackBar('Username is required!');
    else if(this.password.length==0) this.openSnackBar('Password is required!');
    else{
      this.disabledButton = true;
      let data = {comments: this.comments, files: this.newFile ? this.files: [], notes: this.newNote ? this.notes: {data: '', deltaJson: []}};
      this.media.getIcon(this.icon)
        .subscribe({
          next: async (res: any)=>{
              let iconData: any;
              if(res.icon.data.includes('https://www.google.com/s2/favicons?sz=64') || res.icon.data.includes('assets/images/predefined-icons')){
                iconData = res.icon;
              }else{
                let my_password_data = new Uint8Array(JSON.parse(res.icon.data).data);
                let string_pass_char = my_password_data.reduce((data, byte)=> { return data + String.fromCharCode(byte) }, '');
                let passBase64String = btoa(string_pass_char);
                iconData = {id: res.icon.id, data: this.sanitizer.bypassSecurityTrustUrl('data:'+ res.icon.type + ';base64,' + passBase64String)['changingThisBreaksApplicationSecurity'] };
              }
              let containerID = this.container.id;
              let passEncryption = '';
              let dataEncryption = '';

              if(this.type=='shared') {
                  passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedRecipientKey);
                  dataEncryption =  await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedRecipientKey)
              }else if(this.type=='own'){
                  passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedOwnerKey);
                  dataEncryption =  await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedOwnerKey)
              }else{
                  passEncryption = await this.encryptDecrypt.encryptData(this.password, this.container.decryptedBackUpPersonKey);
                  dataEncryption =  await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedBackUpPersonKey)
              }
              let dataToSave = { url: this.url, name: this.name, icon: this.icon, userName: this.userName, password: passEncryption, passData: dataEncryption};
              const { size, restStorage } = this.calculateMemory(dataToSave, res.icon, data);

              if(restStorage > 0 ){
  
                this.containerPassword.addPassword(dataToSave, size, containerID, this.user.id)
                  .subscribe({
                      next: async (result: any)=>{
                        if(this.type=='own'){
                            this.dexieService.getOwnContainers().then((dt: any)=>{
                              let d = dt;
                              let pass =  this.container.passwords;
                              let decrpass =  this.container.decryptedPasswords;
                              pass.push(result.password);
                              decrpass.push({url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, owner: this.user.id, id: result.password, size, ...data});
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, passwords: pass, decryptedPasswords: decrpass};
                              this.dexieService.setOwnContainers(d);
                              this.containerService.setOwnContainers(d);
                              this.successAdd();
                            });

                        }else if(this.type=='shared'){
                            this.dexieService.getSharedContainers().then((dt: any)=>{
                              let d = dt;
                              let pass =  this.container.passwords;
                              let decrpass =  this.container.decryptedPasswords;
                              pass.push(result.password);
                              decrpass.push({url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, owner: this.user.id, id: result.password, size, ...data});
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, passwords: pass, decryptedPasswords: decrpass};
                              this.dexieService.setSharedContainers(d);
                              this.containerService.setSharedContainers(d);
                              this.successAdd();
                            });

                        }else{
                            this.dexieService.getDeadManSwitchContainers().then((dt: any)=>{
                              let d = dt;
                              let pass =  this.container.passwords;
                              let decrpass =  this.container.decryptedPasswords;
                              pass.push(result.password);
                              decrpass.push({url: this.url, name: this.name, userName: this.userName, password: this.password, icon: iconData, owner: this.user.id, id: result.password, size, ...data});
                              let newMemory = JSON.parse(result.newMemory);
                              d[this.index] = {...this.container, usedMemory: {...newMemory, memory: Number(newMemory.memory)}, passwords: pass, decryptedPasswords: decrpass};
                              this.dexieService.setDeadManSwitchContainers(d);
                              this.containerService.setDeadManSwitchContainers(d);
                              this.successAdd();
                            });
                          }

                    },
                    error: (error: HttpErrorResponse)=>{
                      this.openSnackBar('Password cannot be created!');
                      this.disabledButton = false;
                    }
                });
          } else{
              this.disabledButton = false;
              this.openSnackBar('Password cannot be added! You reached the limit of your storage! Please upgrade your account to save more data with us!');
          }
        },
        error: (error: HttpErrorResponse)=>{
          this.disabledButton = false;
        }
      });
    }
  }

  successDelete(){
      this.activeItem = -1;
      this.comment = '';
      this.dialog.closeAll();
      this.disabledButton = false;
      this.isAddPassword = false;
      this.openSnackBar('Password deleted successfully!');
  }

  successAdd(){
      this.cancel();
      this.dialog.closeAll();   
      this.openSnackBar('Password added successfully!');
  }

  successEdit(){
      this.dialog.closeAll();
      this.disabledButton = false;
      this.disabled = false;
      this.disableEdit = true;
      this.openSnackBar('Password updated successfully!');
  }
}
