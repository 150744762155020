<div class="dashboard-card settings-content" fxLayout="column" fxLayoutAlign="space-between">
    <div fxLayout="column" fxLayoutGap="20px">
        <div>
            <span class="title">Plan Settings</span>
        </div>
    
        <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-spinner diameter="50" color="accent"></mat-spinner>
                  <div class="pop-ups-subtitle">Please wait we are fetching your data</div>
            </div>
        </div>

        <div *ngIf="!loading"  class="storage">
            <div class="mt-6">
              <span class="settings-title p-4 ml-2 ">My Storage</span>
            </div>
            <div class="flex p-4 content-center items-center">
                <svg class="color-change" xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17" fill="none">
                    <path d="M17.9895 5.30453C17.4792 3.9691 16.5832 2.82129 15.419 2.01195C14.2546 1.20266 12.8765 0.769531 11.4652 0.769531C10.054 0.769531 8.67596 1.20266 7.51154 2.01195C6.34735 2.82123 5.45123 3.96907 4.94102 5.30453C3.53084 5.46206 2.23359 6.16209 1.31617 7.26055C0.398636 8.35911 -0.069317 9.77263 0.00832083 11.2108C0.0859741 12.6487 0.703487 14.0019 1.73368 14.992C2.76402 15.9821 4.1288 16.5339 5.54745 16.534H17.3827C18.8014 16.5339 20.1662 15.9821 21.1965 14.992C22.2267 14.0019 22.8442 12.6488 22.9218 11.2108C22.9995 9.77269 22.5315 8.35917 21.614 7.26055C20.6965 6.16212 19.3993 5.46208 17.9891 5.30453H17.9895ZM17.3829 14.2812H5.5477C4.35844 14.2812 3.25962 13.6372 2.66499 12.5918C2.07036 11.5464 2.07036 10.2582 2.66499 9.21282C3.25962 8.16742 4.35844 7.52336 5.5477 7.52336H6.61657L6.83111 6.67863C7.18339 5.27452 8.1352 4.10347 9.42632 3.48585C10.7176 2.86807 12.2131 2.86807 13.5042 3.48585C14.7954 4.10363 15.7472 5.27468 16.0994 6.67863L16.314 7.52336H17.3829C18.5721 7.52336 19.6709 8.16742 20.2656 9.21282C20.8602 10.2582 20.8602 11.5464 20.2656 12.5918C19.6709 13.6372 18.5721 14.2812 17.3829 14.2812H17.3829Z"/>
                </svg>
                <p class="storage-details ">{{used.memory | number:'1.3-3'}} {{used.unit}} of {{userPlan.memory.memory}} {{userPlan.memory.unit}}  used</p>
            </div>
            <div class="p-4">
                <div class="flex justify-end items-end justify-items progress-label">{{ usedPourcentage | number:'1.2-2' }}% of storage used</div>
                <mat-progress-bar  class="mt-2" mode="determinate" [value]="usedPourcentage" color="primary"></mat-progress-bar>
            </div>
    
        </div>

        <div *ngIf="!loading" class="storage-details-box" fxLayout="column" fxLayoutGap="10px" >
            <div class="item-box">
                <span class="pricing-card-description">Current Plan: {{userPlan.name}} </span>
            </div>
            <div class="item-box">
                <span class="pricing-card-description">Number of containers: {{ownContainers.length + sharedContainers.length + deadManSwitchContainers.length}} </span>
            </div>
            <div class="item-box">
                <span class="pricing-card-description">Used Storage: {{used.memory | number:'1.3-3' }} {{used.unit}}</span>
            </div>
            <div class="item-box">
                <span class="pricing-card-description">Free Storage: {{freeMemory.memory | number:'1.3-3'}} {{freeMemory.unit}} </span>
            </div>
            <div fxLayout="row" fxLayoutGap="10px"  class="item-box">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7887 9.26345L8.10455 11.5793L12.7363 6.9476M16.982 9.26345C16.982 13.5268 13.5258 16.983 9.26247 16.983C4.99911 16.983 1.54297 13.5268 1.54297 9.26345C1.54297 5.00009 4.99911 1.54395 9.26247 1.54395C13.5258 1.54395 16.982 5.00009 16.982 9.26345Z" stroke="#286286" stroke-width="1.5439" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span class="pricing-card-description">Storage usage of Passwords:  {{passwordsMemory.memory | number:'1.3-3'}} {{passwordsMemory.unit}} </span>

            </div>
            <div fxLayout="row" fxLayoutGap="10px"  class="item-box">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7887 9.26345L8.10455 11.5793L12.7363 6.9476M16.982 9.26345C16.982 13.5268 13.5258 16.983 9.26247 16.983C4.99911 16.983 1.54297 13.5268 1.54297 9.26345C1.54297 5.00009 4.99911 1.54395 9.26247 1.54395C13.5258 1.54395 16.982 5.00009 16.982 9.26345Z" stroke="#286286" stroke-width="1.5439" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <span class="pricing-card-description">Storage usage of Notebooks:  {{notebooksMemory.memory | number:'1.3-3'}} {{notebooksMemory.unit}} </span>
            </div>

        </div>

        <div>
            <span class="settings-title">Update your plan</span>
        </div>
        
        <div fxLayout="row wrap" 
          fxLayoutGap.xl="20px grid"
          fxLayoutGap.lg="15px grid"
          fxLayoutGap.md="20px grid"
          fxLayoutGap.sm="15px grid"
          fxLayoutGap.xs="15px grid" >
            <div
            fxFlex.xl="25"
            fxFlex.lg="25"
            fxFlex.md="50"
            fxFlex.sm="50"
            fxFlex.xs="100"
           *ngFor="let p of finalPlans; let i=index" fxLayoutAlign="stretch stretch" fxLayout="row">              
                <div class="pricing-card-container" [class.disabled]="p.name === userPlan.name">
                      <div class="flex items-center justify-items justify-center pricing-card-name">{{p.name}}</div>
                      <div *ngIf="p.monthlyPrice!=='$0'" class="flex items-center justify-items justify-center mt-4 pricing-card-price "> {{p.monthlyPrice}}/month </div>
                      <div *ngIf="p.monthlyPrice==='$0'" class="flex items-center justify-items justify-center mt-4 pricing-card-price invisible"> {{p.monthlyPrice}}/month </div>
                      <div *ngIf="p.monthlyPrice==='$0'" class="flex items-center justify-items justify-center mt-4 pricing-card-billing-mode invisible"> billed annually* </div>
                      <div *ngIf="p.monthlyPrice!=='$0'" class="flex items-center justify-items justify-center mt-4 pricing-card-billing-mode">billed annually*</div>
                    <div>
                    
                        <div class="flex mt-10">
                            <img src="assets/images/star.svg" alt="star">

                            <span class="pricing-card-description ml-2 ">{{p.recipients}} recipients</span>
                        </div>
                        <div class="flex ">
                            <img src="assets/images/star.svg" alt="star">

                            <span class="pricing-card-description ml-2">{{p.containers}} containers</span>
                        </div>
                        <div class="flex ">
                            <img src="assets/images/star.svg" alt="star">

                            <span class="pricing-card-description ml-2">{{p.memory.memory}} {{p.memory.unit}} storage</span>
                        </div>
                    </div>
    
                    <div  class="flex justify-center items-center justify-items mt-3" *ngIf="p.name !== userPlan.name">
                        <button class="cssbuttons-io-button mt-10 p-4" (click)="getPlan(p)" [class.disabled]="selectedPlan.name === userPlan.name">
                            {{userPlan.id==p.id ? 'Current Plan' : (userPlan.id>p.id ? 'Downgrade Plan': 'Upgrade Plan' )}}
                            <div class="icon">
                              <svg
                                height="24"
                                width="24"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path
                                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </div>
                          </button>                     
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>
<ng-template #payementDialog>
    <mat-dialog-content class="dialog-section"  fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="25px">
      <div fxLayout="column">
        <div class="flex justify-items items-center justify-center payment-dialog-title">
          Checkout
        </div>
        <hr class="hr" />
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="20px" class="mt-3 p-6">
            <div class="p-6">
              <div class="payment">
                <label class="relative flex items-end justify-end justify-items p-2">
                    <input type="radio" name="paymentMode" value="stripe" checked (change)="handleCheckboxChange($event)" id="stripe">
                  <div class="checkmark"></div>
                </label>
                <div class="flex justify-items items-center justify-center">
                  <img src="assets/images/dashboard/payment/stripe.png" alt="star">
                </div>
              </div>
              <p class="flex justify-items items-center justify-center payment-description">With Stripe</p>
            </div>
            <div class="p-6">
              <div class="payment">
                <label class="relative flex items-end justify-end justify-items p-2">
                    <input type="radio" name="paymentMode" value="paypal" (change)="handleCheckboxChange($event)" id="paypal">
                  <div class="checkmark"></div>
                </label>
                <div class="flex justify-items items-center justify-center">
                  <img src="assets/images/dashboard/payment/paypal.png" alt="star">
                </div>
              </div>
              <p class="flex justify-items items-center justify-center payment-description">With Paypal</p>
            </div>
          </div>
        <div class="payment-content" >
            <div class="p-2">
                <div class="form-details">
                    Order Summary
                </div>
                <hr class="mt-2" />
                <div>
                    <div class="flex mt-4 ">
                        <div fxFlex="50%" class="flex justify-start items-start justify-items form-details">Plan:</div>
                        <div fxFlex="50%" class="flex justify-end items-end justify-items form-details">{{selectedPlan.name}}</div>
                    </div>
                    <div class="flex mt-4 ">
                        <div fxFlex="50%" class="flex justify-start items-start justify-items form-details">Price:</div>
                        <div fxFlex="50%" class="flex justify-end items-end justify-items form-details">{{selectedPlan.yearlyPrice}} / Year</div>
                    </div>
                    <div class="flex mt-4 ">
                        <div fxFlex="50%" class="flex justify-start items-start justify-items form-details">Recipients:</div>
                        <div fxFlex="50%" class="flex justify-end items-end justify-items form-details">{{selectedPlan.recipients}}</div>
                    </div>
                    <div class="flex mt-4 ">
                        <div fxFlex="50%" class="flex justify-start items-start justify-items form-details">Containers:</div>
                        <div fxFlex="50%" class="flex justify-end items-end justify-items form-details">{{selectedPlan.containers}}</div>
                    </div>
                    <div class="flex mt-4 ">
                        <div fxFlex="50%" class="flex justify-start items-start justify-items form-details">Storage:</div>
                        <div fxFlex="50%" class="flex justify-end items-end justify-items form-details">{{selectedPlan.memory.memory}} {{selectedPlan.memory.unit}}</div>
                    </div>
                    <hr class="mt-2">
                    <div class="flex mt-4">
                        <div fxFlex="50%" class="flex justify-start items-start justify-items form-details">Total</div>
                        <div fxFlex="50%" class="flex justify-end items-end justify-items form-details">{{selectedPlan.yearlyPrice}}/year</div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center">
                  <button fxFlex="100%" class="pop-ups-button mt-4 pointer" (click)="checkoutPlan()" [disabled]="disabled" [ngClass]="{'opaque': disabled, '': !disabled}">Checkout</button>
                </div>
            </div>          
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>
