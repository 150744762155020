<div class="login-register-background" fxLayout="column" fxLayoutGap="50px" fxLayoutAlign="center center">
    <div class="form-content" fxLayout="column" fxLayoutGap="25px" >
        <div class="pointer" (click)="navigateToLandingPage()">
            <img width="150" height="55" alt="syfly-logo" ngSrc="assets/images/syfly-logo.svg"/>
        </div>
        <div class="login-title">Reset your password</div>

        <div class="login-subtitle">Enter the new password</div>

        <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" class="login-input" placeholder="Password." [(ngModel)]="password" (keydown.enter)="save()"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="shiw-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " (click)="isPassword()" class="show-hide"/>
            </div>
        </div>
        <div class="error" *ngIf="password.length<8">
            Password should have minimum 8 characters
        </div>
        <div class="error" *ngIf="password.length>25">
            Password should have maximum 25 characters
        </div>

        <div fxLayout="row" class="relative">
            <input [type]="show ? 'text' : 'password'" class="login-input" placeholder="Confirm Password." [(ngModel)]="confirmPassword" (keydown.enter)="save()"/>
            <div fxLayout="row" fxLayoutAlign="center center" class="eye-div">
                <img alt="shiw-hide" [src]="show ? 'assets/images/show.svg' : 'assets/images/hide.svg' " (click)="isPassword()" class="show-hide"/>
            </div>
        </div>
        <div class="error" *ngIf="password!==confirmPassword && confirmPassword.length!==0">
            Password does not match
        </div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <button type="submit" class="purple-button" [ngClass]="{'opaque': disabled, '':disabled}"  [disabled]="disabled" (click)="save()">Save</button>
        </div>
    </div>
</div>