import { NotebooksDetailsComponent } from './dashboard/notebooks/notebooks-details/notebooks-details.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGard } from './services/authgard/authgard.service';
import { LandingPageComponent } from './external-pages/landing-page/landing-page.component';
import { ForgotPasswordRequestComponent } from './auth/forgot-password-request/forgot-password-request.component';
import { VerifyComponent } from './auth/verify/verify.component';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { DownloadPageComponent } from './external-pages/download-page/download-page.component';
import { SuccessPageComponent } from './external-pages/success-page/success-page.component';
import { ContainersComponent } from './dashboard/containers/containers.component';
import { SharedComponent } from './dashboard/shared/shared.component';
import { ContainerContentComponent } from './dashboard/container-content/container-content.component';
import { ContainerDetailsComponent } from './dashboard/container-details/container-details.component';
import { ProfileSettingsComponent } from './dashboard/settings/profile-settings/profile-settings.component';
import { ImportSettingsComponent } from './dashboard/settings/import-settings/import-settings.component';
import { BrowserImportDetailsComponent } from './dashboard/settings/import-settings/browser-import-details/browser-import-details.component';
import { TowFASettingsComponent } from './dashboard/settings/tow-fasettings/2fa-settings.component';
import { TimerConfigurationComponent } from './dashboard/settings/timer-configuration/timer-configuration.component';
import { SettingsPlanComponent } from './dashboard/settings/settings-plan/settings-plan.component';
import { BackupPersonComponent } from './dashboard/settings/backup-person/backup-person.component';
import { PasswordChangeComponent } from './dashboard/settings/password-change/password-change.component';
import { ChangeMasterPasswordComponent } from './dashboard/settings/change-master-password/change-master-password.component';
import { RegisterPageComponent } from './auth/register-page/register-page.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PrivacyPolicyComponent } from './external-pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './external-pages/terms-conditions/terms-conditions.component';
import { ContactUsPageComponent } from './external-pages/contact-us-page/contact-us-page.component';
import { CustomerSupportPageComponent } from './external-pages/customer-support-page/customer-support-page.component';
import { NotificationsComponent } from './dashboard/settings/notifications/notifications.component';
import { ListContainersComponent } from './dashboard/list-containers/list-containers.component';
import { FirstStepsCreateContainerComponent } from './dashboard/first-steps-create-container/first-steps-create-container.component';
import { SharingPersonForAdvancedStepperComponent } from './dashboard/sharing-person-for-advanced-stepper/sharing-person-for-advanced-stepper.component';
import { StartBrowserImportDetailsComponent } from './dashboard/start-migration/browser-import-details/browser-import-details.component';
import { StartMigrationComponent } from './dashboard/start-migration/start-migration.component';
import { BackupPersonStepperComponent } from './dashboard/backup-person-stepper/backup-person-stepper.component';
import { NotebooksComponent } from './dashboard/notebooks/notebooks.component';
import { TrashComponent } from './dashboard/settings/trash/trash.component';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { SecondStepsCreateContainerComponent } from './dashboard/second-steps-create-container/second-steps-create-container.component';
import { environment } from 'src/environments/environment';
import { TutorialsPageComponent } from './external-pages/tutorials-page/tutorials-page.component';
import { NotFoundPageComponent } from './external-pages/not-found-page/not-found-page.component';

const routes: Routes = [
  { 
    path: '', 
    component: environment.useLazyLoading ? undefined : LandingPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/landing-page/landing-page.component').then(m => m.LandingPageComponent) : undefined, 
    pathMatch: 'full'
  },
  { 
    path: 'login/:origin', // if origin is 0 the redirection was from the web platform, else if 1 it is from the web extension
    component: environment.useLazyLoading ? undefined : LoginPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/login-page/login-page.component').then(m => m.LoginPageComponent) : undefined
  },
  { 
    path: 'login',
    redirectTo: 'login/0'
  },
  { 
    path: 'register/:planId', 
    component: environment.useLazyLoading ? undefined : RegisterPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/register-page/register-page.component').then(m => m.RegisterPageComponent) : undefined
  },
  { 
    path: 'register',
    redirectTo: 'register/2'
  },
  { 
    path: '2fa', 
    component: environment.useLazyLoading ? undefined : VerifyComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/verify/verify.component').then(m => m.VerifyComponent) : undefined
  },
  { 
    path: 'forget-password', 
    component: environment.useLazyLoading ? undefined : ForgotPasswordRequestComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/forgot-password-request/forgot-password-request.component').then(m => m.ForgotPasswordRequestComponent) : undefined
  },
  { 
    path: 'forgot-password/:token', 
    component: environment.useLazyLoading ? undefined : ForgotPasswordComponent,
    loadComponent: environment.useLazyLoading ? () => import('./auth/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent) : undefined
  },
  { 
    path: 'privacy-policy', 
    component: environment.useLazyLoading ? undefined : PrivacyPolicyComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) : undefined
  },
  { 
    path: 'terms-conditions', 
    component: environment.useLazyLoading ? undefined : TermsConditionsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/terms-conditions/terms-conditions.component').then(m => m.TermsConditionsComponent) : undefined
  },
  { 
    path: 'contact-us', 
    component: environment.useLazyLoading ? undefined : ContactUsPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/contact-us-page/contact-us-page.component').then(m => m.ContactUsPageComponent) : undefined
  },
  { 
    path: 'customer-support',
    component: environment.useLazyLoading ? undefined : CustomerSupportPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/customer-support-page/customer-support-page.component').then(m => m.CustomerSupportPageComponent) : undefined
  },
  { 
    path: 'download', 
    component: environment.useLazyLoading ? undefined : DownloadPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/download-page/download-page.component').then(m => m.DownloadPageComponent) : undefined
  },
  { 
    path: 'tutorials', 
    component: environment.useLazyLoading ? undefined : TutorialsPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/tutorials-page/tutorials-page.component').then(m => m.TutorialsPageComponent) : undefined
  },
  { 
    path: 'success/:show',  // if 0 signup, else extension
    component: environment.useLazyLoading ? undefined : SuccessPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/success-page/success-page.component').then(m => m.SuccessPageComponent) : undefined
  },
  {
    path: 'home',
    component: environment.useLazyLoading ? undefined : ContainersComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/containers/containers.component').then(m => m.ContainersComponent) : undefined, 
    canActivate: [AuthGard],
    children: [
        { 
            path: '', 
            component: environment.useLazyLoading ? undefined : ListContainersComponent, 
            loadComponent: environment.useLazyLoading ? () => import('./dashboard/list-containers/list-containers.component').then(m => m.ListContainersComponent) : undefined,
            canActivate: [AuthGard]
          },
        {
          path: 'create-container', 
          component: environment.useLazyLoading ? undefined : FirstStepsCreateContainerComponent, 
          loadComponent: environment.useLazyLoading ? () => import('./dashboard/first-steps-create-container/first-steps-create-container.component').then(m => m.FirstStepsCreateContainerComponent) : undefined, 
          canActivate: [AuthGard]
        },
        {
          path: 'container-encryption/:type', 
          component: environment.useLazyLoading ? undefined : SecondStepsCreateContainerComponent, 
          loadComponent: environment.useLazyLoading ? () => import('./dashboard/second-steps-create-container/second-steps-create-container.component').then(m => m.SecondStepsCreateContainerComponent) : undefined,
          canActivate: [AuthGard]
        },
        {
          path: 'backup-person', 
          component: environment.useLazyLoading ? undefined : BackupPersonStepperComponent, 
          loadComponent: environment.useLazyLoading ? () => import('./dashboard/backup-person-stepper/backup-person-stepper.component').then(m => m.BackupPersonStepperComponent) : undefined,
          canActivate: [AuthGard]
        },
        {
          path: 'sharing-for-advanced', 
          component: environment.useLazyLoading ? undefined : SharingPersonForAdvancedStepperComponent, 
          loadComponent: environment.useLazyLoading ? () => import('./dashboard/sharing-person-for-advanced-stepper/sharing-person-for-advanced-stepper.component').then(m => m.SharingPersonForAdvancedStepperComponent) : undefined,
          canActivate: [AuthGard]
        },
        {
          path: 'start-browser-import-details/:browser', 
          component: environment.useLazyLoading ? undefined : StartBrowserImportDetailsComponent, 
          loadComponent: environment.useLazyLoading ? () => import('./dashboard/start-migration/browser-import-details/browser-import-details.component').then(m => m.StartBrowserImportDetailsComponent) : undefined,
          canActivate: [AuthGard]
        },
        {
          path: 'start-migration', 
          component: environment.useLazyLoading ? undefined : StartMigrationComponent ,
          loadComponent: environment.useLazyLoading ? () => import('./dashboard/start-migration/start-migration.component').then(m => m.StartMigrationComponent) : undefined, 
          canActivate: [AuthGard]
        },
    ]
  },
  {
    path: 'shared',
    component: environment.useLazyLoading ? undefined : SharedComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/shared/shared.component').then(m => m.SharedComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path: 'container-content/:type/:index',
    component: environment.useLazyLoading ? undefined : ContainerContentComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/container-content/container-content.component').then(m => m.ContainerContentComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path: 'container-details/:type/:index',
    component: environment.useLazyLoading ? undefined : ContainerDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/container-details/container-details.component').then(m => m.ContainerDetailsComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path: 'profile-settings',
    component: environment.useLazyLoading ? undefined : ProfileSettingsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/profile-settings/profile-settings.component').then(m => m.ProfileSettingsComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path: 'import-settings',
    component: environment.useLazyLoading ? undefined : ImportSettingsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/import-settings/import-settings.component').then(m => m.ImportSettingsComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path: 'trash',
    component: environment.useLazyLoading ? undefined : TrashComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/trash/trash.component').then(m => m.TrashComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path:'browser-import-details/:browser',
    component: environment.useLazyLoading ? undefined : BrowserImportDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/import-settings/browser-import-details/browser-import-details.component').then(m => m.BrowserImportDetailsComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path:'notebooks/:type/:index',
    component: environment.useLazyLoading ? undefined : NotebooksComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/notebooks/notebooks.component').then(m => m.NotebooksComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path:'notebook-details/:type/:index/:notebook',
    component: environment.useLazyLoading ? undefined : NotebooksDetailsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/notebooks/notebooks-details/notebooks-details.component').then(m => m.NotebooksDetailsComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path:'auth-settings',
    component: environment.useLazyLoading ? undefined : TowFASettingsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/tow-fasettings/2fa-settings.component').then(m => m.TowFASettingsComponent) : undefined, 
    canActivate: [AuthGard]
  },{
    path:'timer-configuration',
    component: environment.useLazyLoading ? undefined : TimerConfigurationComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/timer-configuration/timer-configuration.component').then(m => m.TimerConfigurationComponent) : undefined,
    canActivate: [AuthGard]
  },
    { 
    path: 'plan-settings',
    component: environment.useLazyLoading ? undefined : SettingsPlanComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/settings-plan/settings-plan.component').then(m => m.SettingsPlanComponent) : undefined,
    canActivate: [AuthGard]
  },
  { 
    path: 'plan-settings/:newPlan',
    component: environment.useLazyLoading ? undefined : SettingsPlanComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/settings-plan/settings-plan.component').then(m => m.SettingsPlanComponent) : undefined,
    canActivate: [AuthGard]
  },
  {
    path:'backup-settings',
    component: environment.useLazyLoading ? undefined : BackupPersonComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/backup-person/backup-person.component').then(m => m.BackupPersonComponent) : undefined, 
    canActivate: [AuthGard]
  },
  {
    path:'password-change',
    component: environment.useLazyLoading ? undefined : PasswordChangeComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/password-change/password-change.component').then(m => m.PasswordChangeComponent) : undefined,
    canActivate:[AuthGard]
  },
  {
    path:'master-password',
    component: environment.useLazyLoading ? undefined : ChangeMasterPasswordComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/change-master-password/change-master-password.component').then(m => m.ChangeMasterPasswordComponent) : undefined,
    canActivate:[AuthGard]
  },
  {
    path:'notifications',
    component: environment.useLazyLoading ? undefined : NotificationsComponent,
    loadComponent: environment.useLazyLoading ? () => import('./dashboard/settings/notifications/notifications.component').then(m => m.NotificationsComponent) : undefined,
    canActivate:[AuthGard]
  },
  {
    path: 'admin',
    component: environment.useLazyLoading ? undefined : AdminPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./admin-page/admin-page.component').then(m => m.AdminPageComponent) : undefined,
  },
  { 
    path: 'not-found', 
    component: environment.useLazyLoading ? undefined : NotFoundPageComponent,
    loadComponent: environment.useLazyLoading ? () => import('./external-pages/not-found-page/not-found-page.component').then(m => m.NotFoundPageComponent) : undefined 
  },
  { 
    path: '**', 
    redirectTo: '/not-found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" , onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
