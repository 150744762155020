<div class="oops-page" fxLayout="column">
  <img
    width="150"
    class="logo"
    alt="syfly"
    src="assets/images/syfly-logo.svg"
    (click)="navigateToLandingPage()"
  />

  <img class="oops" alt="oops" src="assets/images/oops.svg" />

  <button class="back-button" (click)="navigateToLandingPage()">
    Go back Home
  </button>
</div>
