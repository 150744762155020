  <mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
      <app-sidebar-content [selected]="type" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>

    <mat-sidenav-content class="sidebar-content">
        <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content>

        <div fxLayout="row" fxLayout.xs="column" class="margin-top-content" >

            <!-- First Column -->
            <div *ngIf="!isSmallScreen || (isSmallScreen && isFirstPage)" fxFlex="50%" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="stretch" class="first-column">
                <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="20px">
                        <div class="group" fxFlex>
                            <svg class="icon" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
                            <input placeholder="Search" type="search" class="input" [(ngModel)]="searchString" autocomplete="off">
                        </div>

                      <img class="pointer smaller-icons" [src]="dark ? 'assets/images/dashboard/add-dark.svg' : 'assets/images/dashboard/add.svg'" alt="add" (click)="addPassword()">
                </div>

                <!-- <div *ngIf="type=='own'" class="container-title">{{ownContainers[index]?.name}}</div>
                <div *ngIf="type=='shared'" class="container-title">{{sharedContainers[index]?.name}}</div>
                <div *ngIf="type!='own' && type!='shared'" class="container-title">{{deadManSwitchContainers[index]?.name}}</div> -->

                <div *ngIf="type=='own' && ownContainers[index]?.decryptedPasswords.length>0" fxLayout="column" >
                    <div *ngFor="let password of ownContainers[index]?.decryptedPasswords | search : searchString ; let i=index;" (click)="selectItem(i)" class="item-card"  [ngClass]="{'highlighted': activeItem===i, '': activeItem!==i}">
                      <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-3 pr-3 pt-4 pb-4">
                        <div fxLayout="row" fxLayoutGap="15px" class="pointer">
                          <img alt="icon" class="item-icon flex items-start justify-items" [src]="password.icon.data"/>
                          <div>
                            <p class="item-title">{{password.name}}</p>
                            <p class="item-detail" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                              <span (click)="selectItem(i)">{{password.url}}</span>
                            </p>
                          </div>
                        </div>
                        <img src="assets/images/dashboard/container-content/open-btn.svg" class="flex items-start justify-items pl-3" alt="open-icon" (click)="selectItem(i)">
                      </div>
                      
                      <hr class="separator full-width">
                    </div>
                </div>

                <div *ngIf="type=='shared' && sharedContainers[index]?.decryptedPasswords.length>0" fxLayout="column">
                  <div *ngFor="let password of sharedContainers[index]?.decryptedPasswords | search : searchString ; let i=index;" (click)="selectItem(i)" class="item-card"  [ngClass]="{'highlighted': activeItem===i, '': activeItem!==i}">
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-3 pr-3 pt-4 pb-4">
                      <div fxLayout="row" fxLayoutGap="20px" class="pointer">
                        <img alt="icon" class="item-icon flex items-start justify-items" [src]="password.icon.data"/>
                        <div>
                          <p class="item-title">{{password.name}}</p>
                          <p class="item-detail" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                            <span (click)="selectItem(i)">{{password.url}}</span>
                          </p>
                        </div>
                      </div>
                      <img src="assets/images/dashboard/container-content/open-btn.svg" class="flex items-start justify-items" alt="open-icon" (click)="selectItem(i)">
                    </div>
                    
                    <hr class="separator full-width">
                  </div>
              </div>

              <div *ngIf="type!='own' && type!='shared' && deadManSwitchContainers[index]?.decryptedPasswords.length>0" fxLayout="column">
                <div *ngFor="let password of deadManSwitchContainers[index]?.decryptedPasswords| search : searchString ; let i=index;" (click)="selectItem(i)" class="item-card"  [ngClass]="{'highlighted': activeItem===i, '': activeItem!==i}">
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="pl-3 pr-3 pt-4 pb-4" >
                    <div fxLayout="row" fxLayoutGap="20px" class="pointer">
                      <img alt="icon" class="item-icon flex items-start justify-items" [src]="password.icon.data"/>
                      <div>
                        <p class="item-title">{{password.name}}</p>
                        <p class="item-detail" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                          <span (click)="selectItem(i)">{{password.url}}</span>
                        </p>
                      </div>
                    </div>
                    <img src="assets/images/dashboard/container-content/open-btn.svg" class="flex items-start justify-items" alt="open-icon" (click)="selectItem(i)">
                  </div>
                  
                  <hr class="separator full-width">
                </div>
            </div>

              <div class="item-title" *ngIf="(type!='own' && type!='shared' && deadManSwitchContainers[index]?.decryptedPasswords.length===0) || (type=='shared' && sharedContainers[index]?.decryptedPasswords.length===0) || (type=='own' && ownContainers[index]?.decryptedPasswords.length===0) ">
                  No items to display 
              </div>
            </div>

            <!-- Second Column -->
              <div *ngIf="!isSmallScreen || (isSmallScreen && !isFirstPage)" fxFlex="50%" class="second-column" >
                <ng-template #nothing>
                    <div fxLayout="row" fxLayoutAlign="center center" class="h-full">
                      <img alt="syfly-opaque" src="assets/images/dashboard/container-content/syfly-opaque.svg"/>
                    </div>
                  </ng-template>

                  <div *ngIf="activeItem!==-1 || isAddPassword; else nothing;" fxLayout="column" fxLayoutGap="20px">

                    <img fxHide.gt-xs class="pointer smaller-icons" src="assets/images/dashboard/back-button.svg" alt="back" (click)="goBack()">

                      <div fxLayout="column" fxLayoutGap="30px">
                          <div fxLayout="row" fxLayoutAlign="space-between center">
                              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                  <img [ngClass]="newAccess ? '' : 'opaque'" [src]="dark ? 'assets/images/dashboard/container-content/accessicon-dark.svg' : 'assets/images/dashboard/container-content/accessicon.svg'" alt="alt">
                                  <div [ngClass]="newAccess ? '' : 'opaque'" class="notes-description">Access</div>
                              </div>
                              <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                                <img class="pointer" alt="delete-item" [src]="dark ? 'assets/images/dashboard/container-content/trash-dark.svg' : 'assets/images/dashboard/container-content/trash.svg'" *ngIf="!disabled && !isAddPassword" (click)="deleteItem()"/>
                                <img class="pointer" alt="edit-item" [src]="dark ? 'assets/images/dashboard/container-content/edit-dark.svg' : 'assets/images/dashboard/container-content/edit.svg'" *ngIf="!disabled && !isAddPassword" (click)="editItem()"/>
                              </div>
                          </div>

                          <hr class="separator full-width">

                          <div *ngIf="newAccess" fxLayout="column" fxLayoutGap="20px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
                              <div class="group">
                                  <img class="profile-photo" [src]="preview" alt="container-icon">
                                  <input type="file" id="myfile" (change)="onIconChange($event)" [disabled]="disabled || disableEdit" accept="image/*"/>
                                  <img *ngIf="!disabled && !disableEdit" class="upload-icon" src="assets/images/dashboard/upload-icon.svg" alt="upload-icon" />
                              </div>
                              <div fxLayout="column" fxLayoutGap="0px" *ngIf="!disabled">
                                  <label class="form-label">Upload Icon</label>
                              </div>
                            </div>
                            <div fxLayout="column" fxLayoutGap="10px">
                              <label class="form-label">Name</label>
                              <input class="form-input" autocomplete="off" type="text" [(ngModel)]="name" [disabled]="disabled || disableEdit" [ngClass]="{'input-highlighted': !disabled && !disableEdit, '': disabled || disableEdit}">
                            </div>
                            <div fxLayout="column" fxLayoutGap="10px">
                              <label class="form-label">Url</label>
                              <div class="flex">
                                    <div fxFlex="30%" class="flex form-input-icon  border-r border-blue-200">
                                      <label  class="form-label underline pointer" (click)="extractFavicon()">extract icon </label>
                                    </div>
                                    <input autocomplete="off" type="text" [(ngModel)]="url" [disabled]="disabled || disableEdit"  class=" form-url-input full-width " [ngClass]="{'input-highlighted': !disabled && !disableEdit, '': disabled || disableEdit}">
                              </div>
                              
                            </div>
                            <div fxLayout="column" fxLayoutGap="10px">
                              <label class="form-label">Username</label>
                              <input autocomplete="off" class="form-input" type="text" [(ngModel)]="userName" [disabled]="disabled || disableEdit" [ngClass]="{'input-highlighted': !disabled && !disableEdit, '': disabled || disableEdit}">
                            </div>
                            <div fxLayout="column" fxLayoutGap="10px">
                              <label class="form-label">Password</label>
                              <div fxLayout="row" class="relative">
                                <input autocomplete="off" class="form-input" [type]="show ? 'text' : 'password'" [(ngModel)]="password" [disabled]="disabled || disableEdit" [ngClass]="{'input-highlighted': !disabled && !disableEdit, '': disabled || disableEdit}">
                                <img  class="hide-show-button" [src]="show ? 'assets/images/show.svg': 'assets/images/hide.svg'" alt="show-hide" (click)="toggleShow()"/>
                              </div>
                            </div>
                          </div>
                      </div>
                      
                      <hr class="separator full-width">

                      <div fxLayout="column" fxLayoutGap="30px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <img [ngClass]="newNote ? '' : 'opaque'" [src]="dark ? 'assets/images/dashboard/container-content/noteicon-dark.svg' : 'assets/images/dashboard/container-content/noteicon.svg'" alt="alt">
                                <div [ngClass]="newNote ? '' : 'opaque'" class="notes-description">Notes</div>
                                <img *ngIf="!newNote && !disabled && !disableEdit" class="pointer" src="assets/images/dashboard/container-content/plus.svg" alt="alt" (click)="addNewNote()" matTooltip="Add Notes from here"/>
                                <img *ngIf="newNote && !disabled && !disableEdit" class="pointer" src="assets/images/dashboard/container-content/minus.svg" alt="alt" (click)="addNewNote()" matTooltip="Remove Notes from here"/>
                            </div>
                            <div class="wysiwyg-container full-width" *ngIf="newNote">
                              <quill-editor [(ngModel)]="notesData" [modules]="modules" (onContentChanged)="onContentChanged($event)" [readOnly]="disabled || disableEdit"></quill-editor>
                          </div>
                      </div>
                  
                      <hr class="separator full-width">

                      <div fxLayout="column" fxLayoutGap="30px">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <img [ngClass]="newFile ? '' : 'opaque'" [src]="dark ? 'assets/images/dashboard/container-content/fileicon-dark.svg' : 'assets/images/dashboard/container-content/fileicon.svg'" alt="alt">
                                <div [ngClass]="newFile ? '' : 'opaque'" class="notes-description">Files</div>
                                <img *ngIf="!newFile && !disabled && !disableEdit" class="pointer" src="assets/images/dashboard/container-content/plus.svg" alt="alt" (click)="addNewFile()" matTooltip="Add Files from here"/>
                                <img *ngIf="newFile && !disabled && !disableEdit" class="pointer" src="assets/images/dashboard/container-content/minus.svg" alt="alt" (click)="addNewFile()" matTooltip="Remove Files from here"/>
                            </div>
                            <div *ngIf="newFile" class="flex justify-center items-center files-block">
                                <label for="fileInput" fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
                                    <img src="assets/images/dashboard/container-content/import-icon.svg" alt="alt">
                                    <p class="file-input">Import File</p>
                                </label>
                                <input id="fileInput" type="file" style="display: none;" [disabled]="disabled || disableEdit" (change)="onFileChange($event)">
                            </div>

                            <div *ngIf="filesToShow.length>0 && newFile" fxLayout="column" fxLayoutGap="15px">
                              <div *ngFor="let file of filesToShow; let i = index;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <img [src]="file.icon" alt="file-icon" width="20px"/>
                                <a
                                  class="form-label underline pointer"
                                  [href]="file.data"
                                  [download]="file.name + file.extension"
                                  >{{file.name}}{{file.extension}}</a>

                                <svg *ngIf="dark && !disableEdit && !disabled" class="pointer" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" (click)="removeFile(i)">
                                    <path d="M18 6L6 18M6 6L18 18" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <svg *ngIf="!dark && !disableEdit && !disabled" class="pointer" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" (click)="removeFile(i)">
                                  <path d="M18 6L6 18M6 6L18 18" stroke="#141522" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              </div>
                            </div>

                      </div>

                      <hr class="separator full-width">

                      <div fxLayout="column" fxLayoutGap="20px">
                          <label class="comment-section-title">Comments</label>
                          <div fxLayout="row" class="relative">
                            <span *ngIf="(!user.profilePicture || user.profilePicture.trim()==='-' || user.profilePicture.trim()==='_' || user.profilePicture.trim().length===0) && (user.firstName!=='' || user.lastName!=='')" class="user-profile-icon" fxLayout="row" fxLayoutAlign="center center"><span>{{user.firstName!.toUpperCase().slice(0,1)}}{{user.lastName!.toUpperCase().slice(0,1)}}</span></span>
                            <span *ngIf="(!user.profilePicture || user.profilePicture.trim()==='-' || user.profilePicture.trim()==='_' || user.profilePicture.trim().length===0) && (user.firstName==='' && user.lastName==='')" class="user-profile-icon" fxLayout="row" fxLayoutAlign="center center"><span>{{user.email.toUpperCase().slice(0,1)}}{{user.email.toUpperCase().slice(0,1)}}</span></span>
                            <img *ngIf="user.profilePicture && user.profilePicture.trim()!=='-' && user.profilePicture.trim()!=='_' && user.profilePicture.trim().length===0" class="user-profile-picture" alt="profile" [src]="user.profilePicture" referrerpolicy="no-referrer"/>
                            <input autocomplete="off" class="comment-input" type="text" placeholder="Add a comment..." [(ngModel)]="comment">
                            <img class="add-comment-button" src="assets/images/dashboard/container-content/sendcomment.svg" alt="alt" (click)="addComment()">
                          </div>

                          <div *ngIf="comments.length>0" fxLayout="column" fxLayoutGap="15px">
                            <div class="comment-section"  *ngFor="let c of comments; let i=index;" fxLayout="column" fxLayoutGap="15px">
                              <div fxLayout="row" fxLayoutAlign="space-between center">
                                  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
                                      <span *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName==null && c.owner.lastName==null)" [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}" >{{c.owner.email.toUpperCase().slice(0,1)}}{{c.owner.email.toUpperCase().slice(0,1)}}</span>
                                      <span *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName==null && c.owner.lastName!=null)" [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}" >{{c.owner.lastName!.toUpperCase().slice(0,1)}}</span>
                                      <span *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName!=null && c.owner.lastName==null)" [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}" >{{c.owner.firstName!.toUpperCase().slice(0,1)}}</span>
                                      <span *ngIf="(!c.owner.profilePicture || c.owner.profilePicture.trim()==='-' || c.owner.profilePicture.trim()==='_' || c.owner.profilePicture.trim().length===0) && (c.owner.firstName!=null && c.owner.lastName!=null)" [ngClass]="{'user-icon-yellow-1': i%2===0, 'user-icon-blue-1':  i%2!==0}" >{{c.owner.firstName!.toUpperCase().slice(0,1)}}{{c.owner.lastName!.toUpperCase().slice(0,1)}}</span>
                                      <img *ngIf="c.owner.profilePicture && c.owner.profilePicture.trim()!=='-' && c.owner.profilePicture.trim()!=='_' && c.owner.profilePicture.trim().length!==0" class="comment-user-1" alt="profile" [src]="c.owner.profilePicture"/>
                                      <div fxLayout="column" fxLayoutAling="center center">
                                        <span class="comment-username" *ngIf="c.owner.firstName!==null || c.owner.lastName!==null">
                                          {{c.owner.firstName}} {{c.owner.lastName}}
                                        </span>
                                        <span class="comment-username" *ngIf="c.owner.firstName==null && c.owner.lastName==null">
                                            {{c.owner.email}}
                                        </span>
                                        <span class="comment-date" >
                                          {{timeDifference(c.createdAt)}}
                                        </span>
                                      </div>
                                  </div>

                                  <div class="dropdown-container" *ngIf="c.owner.id==user.id">
                                    <div>
                                        <img src="assets/images/dashboard/container-card/folder-icon.svg" alt="actions">
                                    </div>
                                    <div class="dropdown-content" >
                                        <div class="flex" (click)="editComment(i)">
                                            <img class="p-1" [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'" alt="edit">
                                            <a class="p-1" >Edit</a>
                                        </div>
                                        <div class="flex" (click)="deleteComment(i)">
                                            <img class="p-1" [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/delete-icon.svg'" alt="delete">
                                            <a class="p-1" >Delete</a>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="comment-data" *ngIf="!editC[i]">
                                {{c.comment}}
                              </div>
                              <input autocomplete="off" *ngIf="editC[i]" class="comment-edit-input" [(ngModel)]="newComment"/>
                              <div *ngIf="editC[i]" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
                                <button class="comment-cancel-btn" (click)="cancelEditComment()">Cancel</button>
                                <button class="comment-save-btn" (click)="saveEditComment(i)">Save</button>
                              </div>
                            </div>
                          </div>
                          
                      </div>

                      <div *ngIf="!isAddPassword && !disabled && !disableEdit" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '':disabledButton}">
                        <button class="cancel-btn" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                        <button class="save-btn" (click)="save()" [disabled]="disabledButton">Save</button>
                      </div>

                      <div *ngIf="isAddPassword" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center" [ngClass]="{'opaque': disabledButton, '':disabledButton}" >
                        <button class="cancel-btn" (click)="cancel()" [disabled]="disabledButton">Cancel</button>
                        <button class="save-btn" (click)="add()" [disabled]="disabledButton">Add</button>
                      </div>
                  </div>
              </div>
        </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- <ng-template #deleteItemDialog>
    <mat-dialog-content>
        <div class="dialog-content" >
            <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">       
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="4" y="4" width="48" height="48" rx="24" fill="#286286"/>
                      <rect x="4" y="4" width="48" height="48" rx="24" stroke="white" stroke-width="8"/>
                      <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancelDeleteItem()">
                      <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="delete-dialog-title">Delete Item</span>
                <span class="delete-dialog-description">Are you sure to move this item to trash?</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}" >
                    <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn full-width" (click)="moveToTrash()" [disabled]="disabledButton">Delete</button>
                </div>
            </div>
            
        </div>
    </mat-dialog-content>
</ng-template> -->

<ng-template #deleteItemDialog>
    <mat-dialog-content>
        <div class="dialog-content" >
            <div *ngIf="disabledButton" class="flex  justify-center items-center justify-items">       
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div *ngIf="!disabledButton" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="4" y="4" width="48" height="48" rx="24" fill="#286286"/>
                      <rect x="4" y="4" width="48" height="48" rx="24" stroke="white" stroke-width="8"/>
                      <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                  <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancelDeleteItem()">
                      <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="delete-dialog-title">Delete Item</span>
                <span class="delete-dialog-description">Are you sure to delete this item</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabledButton, '': !disabledButton}" >
                    <button class="cancel-btn full-width" (click)="cancelDeleteItem()" [disabled]="disabledButton">Cancel</button>
                    <button class="save-btn full-width" (click)="delete()" [disabled]="disabledButton">Delete</button>
                </div>
            </div>
            
        </div>
    </mat-dialog-content>
</ng-template>
