  <div class="card" fxLayout="column" fxLayoutGap="30px">
    <div class="flex flex-row justify-between">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center"  (click)="selectNotebook()">
        <img [alt]="notebook.name" [src]="notebook.icon.data" class="notebook-icon"  (click)="selectNotebook()"/>
        <div fxLayout="column" fxLayoutGap="5px"  (click)="selectNotebook()">
          <span class="card-title">{{notebook.name}}</span>
          <span class="card-subtitle">{{notebook.notes.length}} Notes</span>
        </div>
      </div>
      <div class="dropdown-container" *ngIf="notebook.owner==user.id">
        <div>
            <img src="assets/images/dashboard/container-card/folder-icon.svg" alt="actions">
        </div>
        <div class="dropdown-content" >
            <div class="flex" (click)="editNotebook()">
                <img class="p-1" [src]="dark ? 'assets/images/dashboard/container-card/edit-icon-dark.svg' : 'assets/images/dashboard/container-card/edit-icon.svg'" alt="edit">
                <a class="p-1" >Edit</a>
            </div>
            <div class="flex" (click)="openDeleteDialog()">
                <img class="p-1" [src]="dark ? 'assets/images/dashboard/container-card/delete-icon-dark.svg' : 'assets/images/dashboard/container-card/delete-icon.svg'" alt="delete">
                <a class="p-1" >Delete</a>
            </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-between"  (click)="selectNotebook()">
      <div class="flex items-center gap-1" (click)="selectNotebook()">
        <img alt="calendar" [src]="dark ? 'assets/images/dashboard/calendar-dark.svg' : 'assets/images/dashboard/calendar-light.svg' ">
        <span class="time">{{notebook.createdAt.toDateString().substring(0, notebook.createdAt.toDateString().lastIndexOf(' '))}}</span>
      </div>
      <div class="flex items-center gap-1" (click)="selectNotebook()">
        <img alt="timer" [src]="dark ? 'assets/images/dashboard/timer-dark.svg' : 'assets/images/dashboard/timer-light.svg' ">
        <span class="time">{{notebook.createdAt.getHours() >= 12 ? notebook.createdAt.getHours() - 12 : notebook.createdAt.getHours()}}:{{notebook.createdAt.getMinutes()}} {{notebook.createdAt.getHours() >= 12 ? 'PM' : 'AM'}}</span>
      </div>
    </div>
  </div>

  <!-- <ng-template #deleteDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#286286"/>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="white" stroke-width="8"/>
                    <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <span class="delete-dialog-title">Delete Notebook</span>
            <span class="delete-dialog-description">Are you sure to move this notebook to trash?</span>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn full-width" (click)="moveToTrashNotebook()" [disabled]="disabled">Delete</button>
            </div>
            </div>
    
    </div>
    </mat-dialog-content>
</ng-template> -->

  <ng-template #deleteDialog>
    <mat-dialog-content>
        <div class="dialog-content" fxLayout="column" fxLayoutGap="15px">
            <div fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="48" height="48" rx="24" fill="#286286"/>
                    <rect x="4" y="4" width="48" height="48" rx="24" stroke="white" stroke-width="8"/>
                    <path d="M22.1006 23.418H34.4938" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M26.7483 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M29.8459 26.5195V31.167" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.8733 23.418L23.6479 32.7129C23.6479 33.1238 23.8111 33.5178 24.1016 33.8083C24.3921 34.0988 24.7862 34.262 25.197 34.262H31.3936C31.8045 34.262 32.1985 34.0988 32.4891 33.8083C32.7796 33.5178 32.9428 33.1238 32.9428 32.7129L33.7174 23.418" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.9734 23.4186V21.0949C25.9734 20.8895 26.055 20.6924 26.2003 20.5472C26.3455 20.4019 26.5425 20.3203 26.748 20.3203H29.8463C30.0517 20.3203 30.2487 20.4019 30.394 20.5472C30.5392 20.6924 30.6208 20.8895 30.6208 21.0949V23.4186" stroke="#E8E8E8" stroke-width="1.39424" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <svg class="pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="cancel()">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#D8D8D8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <span class="delete-dialog-title">Delete Notebook</span>
            <span class="delete-dialog-description">Are you sure to delete this notebook</span>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center" class="full-width" [ngClass]="{'opaque': disabled, '': !disabled}">
                <button class="cancel-btn full-width" (click)="cancel()" [disabled]="disabled">Cancel</button>
                <button class="save-btn full-width" (click)="deleteNotebook()" [disabled]="disabled">Delete</button>
            </div>
            </div>
    
    </div>
    </mat-dialog-content>
</ng-template>


<ng-template #noteDialog>
  <mat-dialog-content>
    <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <div class="relative">
          <img class="note-icon" [src]="preview" alt="container-icon">
          <input type="file" id="myfile" (change)="onIconChange($event)" accept="image/*"/>
          <span class="dialog-label">Add Icon</span>
        </div>
        <div
          class="relative full-width mt-3"
          fxLayout="column"
          fxLayoutGap="10px"
        >
          <label for="name" class="dialog-label">Name</label>
          <input
            [(ngModel)]="name"
            autocomplete="off"
            type="text"
            class="dialog-input"
            placeholder="Notebook Name"
            (keydown.enter)="updateNotebook()"
          />
        </div>
      </div>

      <div
        class="mt-3"
        fxLayout="row"
        fxLayoutGap="15px"
        fxLayoutAlign="end center"
      >
        <button class="cancel-btn" (click)="cancel()" [disabled]="disabled">Cancel</button>
        <button class="save-btn" (click)="updateNotebook()" [disabled]="disabled">Update</button>
      </div>
    </div>
  </mat-dialog-content>
</ng-template>