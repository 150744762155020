import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { UserService } from '../user/user.service';
import { DexieService } from '../dexie/dexie.service';
import { ActivityLogsService } from '../activity-logs/activity-logs.service';
import { AuthService } from '../auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthGard {

  // & the role of the authgard is to supervise the validity of the token also to make other things like user profile data fectching in order to prepare the display of the dashboard
  constructor(private router: Router, private localstorage: LocalStorageService, private authService: AuthService, private user: UserService, private dexieService: DexieService, private logsService: ActivityLogsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any{
    if(route.routeConfig?.path==='home' && route.queryParams['data']!==undefined){
      let data = JSON.parse(route.queryParams['data']);
      this.localstorage.setToken(data.token);
      this.localstorage.setEmail(data.email);
      this.localstorage.setFirst(true);
      this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
      this.user.getUserProfileData(data.email);
    }
    const origin = this.localstorage.getOrigin();
    const originPlatform = this.localstorage.getOriginPlatform();
    const token = this.localstorage.getToken();
    const userId = JSON.parse(this.localstorage.getUser())?.id ?? 0;

    if(token) {
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)) {
          if(origin==='web-extension'){
            this.localstorage.removeOrigin();
            if(originPlatform=='macos'){
              this.localstorage.removeOriginPlatform();
              window.location.href = `syfly://com.syfly.io.extension?email=${this.localstorage.getEmail()}`;

            }else{
              this.router.navigate(['/success', 1]);

            }
          }else{

            return true;
          }
      }else{
        // we need to change this block to handle the refresh token
        this.authService.refreshToken(userId, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.logsService.logLogout(userId).subscribe({
                next: (response) => {
                  this.localstorage.clearLocalStorage();
                  this.router.navigate(['./login', 0]);
                },
                error: (error) => {
                  console.error('Error logging logout', error);
                }
              });
            });
          }
        });
      }
    }else{
      console.log('no token');
      this.dexieService.clearDB().then(() => {
          this.logsService.logLogout(userId).subscribe({
            next: (response) => {
              this.localstorage.clearLocalStorage();
              this.router.navigate(['./login', 0]);
            },
            error: (error) => {
              this.localstorage.clearLocalStorage();
              this.router.navigate(['./login', 0]);
              console.error('Error logging logout', error);
            }
          });
        }).catch((err)=>{
            this.localstorage.clearLocalStorage();
            this.router.navigate(['./login', 0]);
        });
      }
  }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }
}
