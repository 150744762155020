import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-add-hardwarekey-for-recipient',
  templateUrl: './add-hardwarekey-for-recipient.component.html',
  styleUrls: ['./add-hardwarekey-for-recipient.component.scss']
})
export class AddHardwarekeyForRecipientComponent {

  @Output("backStep") backStep = new EventEmitter();
  @Input() securityKey = '';
  @Input() data: any;

  hardwareKey = '';
  disabled = false;

  get ownContainers(){
    return this.containerService.ownContainers;
  }

  constructor(private router: Router, private _snackBar: MatSnackBar, private encryptDecrypt: EncryptDecryptService, private localstorage: LocalStorageService, private containerService: ContainerService, private dexieService: DexieService ) {
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async next() {
    if(this.hardwareKey.length>0){
      this.disabled = true;
      // ! here we gonna change the logic of the function to save the data
      let decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
      let encrypted = await this.encryptDecrypt.encryptKey(decrypted, this.hardwareKey.trim().slice(0, 12));
      let recipientKey = this.encryptDecrypt.toBuffer(encrypted);
      // this.localstorage.setContainerData(JSON.stringify({data: {...this.data, recipientsEncryptionMode: 'hardwareKey', recipientsKey: JSON.stringify(recipientKey)}, securityKey: this.securityKey}))
      // this.router.navigate(['home', 'backup-person']);
      this.containerService.addContainer({...this.data, recipientsEncryptionMode: 'hardwareKey', recipientsKey: JSON.stringify(recipientKey), backup: undefined}).subscribe({
        next: (res: any) => {
          this.dexieService.getOwnContainers().then((data: any)=>{
            let binary = this.encryptDecrypt.bufferToBinary(decrypted);
            this.dexieService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
            this.containerService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
          });
          this.openSnackBar('Container added successfully!');
          this.localstorage.removeContainerData();
          this.disabled = false;
          if(this.ownContainers.length==0){
            this.router.navigate(['home', 'start-migration']);
          }else{
            this.router.navigate(['home']);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar('Container cannot be created!');
          this.disabled = false;
        },
      });
    }else{
      this.openSnackBar('Hardware Key is required!');
    }

  }

  cancel(){
    this.backStep.emit();
  }
}
