<!-- <mat-sidenav-container>
    <mat-sidenav [permanentAt]="960" #start [mode]="'side'" class="sidebar">
    <app-sidebar-content [selected]="'import-settings'" [isOpen]="false" (toggleSidebar)="toggleSidebar()"></app-sidebar-content>
    </mat-sidenav>
    <mat-sidenav-content class="sidebar-content">
    <app-navbar-content (toggleSidebar)="toggleSidebar()"></app-navbar-content> -->
      <div fxLayout="column" fxLayoutGap="30px" class="content">
        <div fxLayour="row" fxLayoutAlign="start center" fxLayoutGap="15px">
            <p class="title">Start Migration</p>
              <svg (click)="openDialog()" *ngIf="!dark" class="pointer" matTooltip="Click here to see the help section" width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286"/>
            </svg>
            <svg (click)="openDialog()" *ngIf="dark" class="pointer" matTooltip="Click here to see the help section" width="25" height="25" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF"/>
            </svg>
          </div>
      <div class="mt-8">
       <p class="browsers">Browsers</p>
       <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class=" browser-img" src="assets/images/dashboard/import-settings/edge.svg" (click)="setBrowerName('edge')" alt="browser">
            </div>
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center" >
            <p class="browser-name">Edge (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class=" browser-img" src="assets/images/dashboard/import-settings/chrome.svg" (click)="setBrowerName('chrome')" alt="browser">
            </div>       
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center" >
            <p class="browser-name">Chrome (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class=" browser-img" src="assets/images/dashboard/import-settings/opera.svg" (click)="setBrowerName('opera')" alt="browser">
            </div>        
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">Opera (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class=" browser-img" src="assets/images/dashboard/import-settings/safari.png" (click)="setBrowerName('safari')" alt="browser">
            </div>        
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">Safari (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class=" browser-img" src="assets/images/dashboard/import-settings/firefox.svg" (click)="setBrowerName('firefox')" alt="browser">
            </div>        
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">Firefox (.csv)</p>
            </div>
        </div>
        </div>
        </div>
        <div>
        <p class="browsers">Other Vendors</p>
        <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class="vendor-img" src="assets/images/dashboard/import-settings/1password.svg" (click)="setBrowerName('1password')" alt="browser">
            </div>
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">1Password (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class="vendor-img" src="assets/images/dashboard/import-settings/LastPass.svg" (click)="setBrowerName('lastpass')" alt="browser">
            </div>       
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">LastPass (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class="vendor-img" src="assets/images/dashboard/import-settings/bitwarden.svg" (click)="setBrowerName('bitwarden')" alt="browser">
            </div>      
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">Bitwarden (.csv)</p>
            </div>
        </div>
        <div class="card p-6">
            <div class="flex justify-center items-center">
                <img class="vendor-img" src="assets/images/dashboard/import-settings/Roboform.svg" (click)="setBrowerName('roboform')" alt="browser">
            </div>   
            <hr class="mt-4">
            <div class="flex mt-4 justify-center items-center">
            <p class="browser-name">RoboForm (.csv)</p>
            </div>
        </div>
         <div class="card p-6">
             <div class="flex justify-center items-center">
                 <img class="vendor-img" src="assets/images/dashboard/import-settings/Enpass.svg" (click)="setBrowerName('enpass')" alt="browser">
             </div>        
             <hr class="mt-4">
             <div class="flex mt-4 justify-center items-center">
             <p class="browser-name">Enpass (.csv)</p>
             </div>
         </div>
         <div class="card p-6">
             <div class="flex justify-center items-center">
                 <img class="vendor-img" src="assets/images/dashboard/import-settings/keeper.svg" (click)="setBrowerName('keeper')" alt="browser">
             </div>
         
             <hr class="mt-4">
             <div class="flex mt-4 justify-center items-center">
             <p class="browser-name">Keeper (.csv)</p>
             </div>
 
         </div>
         <div class="card p-6">
             <div class="flex justify-center items-center">
                 <img class="vendor-img" src="assets/images/dashboard/import-settings/NordPassword.svg" (click)="setBrowerName('nordpass')" alt="browser">
             </div>
         
             <hr class="mt-4">
             <div class="flex mt-4 justify-center items-center">
             <p class="browser-name">NordPass (.csv)</p>
             </div>
 
         </div>
         <div class="card p-6">
             <div class="flex justify-center items-center">
                 <img class="vendor-img" src="assets/images/dashboard/import-settings/Zohovault.svg" (click)="setBrowerName('zohovault')" alt="browser">
             </div>
         
             <hr class="mt-4">
             <div class="flex mt-4 justify-center items-center">
             <p class="browser-name">ZohoVault (.csv)</p>
             </div>
 
         </div>
       </div>
       <div class="flex space-x-2 full-width lg:justify-end lg:items-end  md:justify-end md:items-enditems-center justify-center mt-8 justify-items ">
    
        <button
        class="cancel-button" (click)="goHome()">
          Later    
        </button>
       </div>
       </div>

         <!-- This pop up is for help section -->
         <ng-template #helpDialog>
            <mat-dialog-content class="help-section">
                <div fxLayout="column" fxLayoutGap="25px" class="help-content">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <svg *ngIf="!dark" width="29" height="29" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#286286"/>
                            </svg>
                            <svg *ngIf="dark" width="29" height="29" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9583 0C6.7013 0 0 6.7013 0 14.9583C0 23.2152 6.7013 29.9165 14.9583 29.9165C23.2152 29.9165 29.9165 23.2152 29.9165 14.9583C29.9165 6.7013 23.2152 0 14.9583 0ZM16.4541 25.429H13.4624V22.4374H16.4541V25.429ZM19.5504 13.8364L18.2042 15.2125C17.1272 16.3045 16.4541 17.202 16.4541 19.4457H13.4624V18.6978C13.4624 17.0524 14.1355 15.5566 15.2125 14.4646L17.0674 12.5799C17.6208 12.0414 17.9499 11.2935 17.9499 10.4708C17.9499 8.82537 16.6037 7.47913 14.9583 7.47913C13.3128 7.47913 11.9666 8.82537 11.9666 10.4708H8.97495C8.97495 7.165 11.6525 4.48748 14.9583 4.48748C18.264 4.48748 20.9416 7.165 20.9416 10.4708C20.9416 11.7871 20.4031 12.9838 19.5504 13.8364Z" fill="#FFFFFF"/>
                            </svg>
                            <span class="help-title">Help</span>
                        </div>
                        <svg [mat-dialog-close]="true" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                            <rect *ngIf="dark" width="32" height="32" rx="5" fill="#232C34"/>
                            <path *ngIf="dark" d="M22 10L10 22M10 10L22 22" stroke="#286286" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            <rect *ngIf="!dark" width="32" height="32" rx="5" fill="#E5F1FA"/>
                            <path *ngIf="!dark" d="M22 10L10 22M10 10L22 22" stroke="#286286" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <hr class="hr"/>
                    <div class="help-content">
                        Make the switch with ease. SyFly's Migration Settings simplify the process of transferring your data from other password managers. 
                        <br/>“Your Data, Your Transition, Your Peace of Mind”
    
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <button class="pop-ups-button" [mat-dialog-close]="true">Ok</button>
                    </div>
                </div>
            </mat-dialog-content>
        </ng-template>

      </div>
    <!-- </mat-sidenav-content>
  </mat-sidenav-container> -->
