import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-backup-person-stepper',
  templateUrl: './backup-person-stepper.component.html',
  styleUrls: ['./backup-person-stepper.component.scss']
})
export class BackupPersonStepperComponent   implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  @Output() data: any;
  @Output() securityKey = '';
  @Output() recipient = '';
  @Output() message = '';
  @Output() encryptionMode = '3';
  @Output() encryptionKey = '';
  @Output() timer = '';
  @Output() frequency = '';
  @Output() reminder = '';
  showBackupSharing = true;
  isMobile = false;
  disabledButton = false;

  get ownContainers(){
    return this.containerService.ownContainers;
  }
  
  constructor(private localstorage: LocalStorageService, private containerService: ContainerService, private router: Router, private _snackBar: MatSnackBar, private breakpointObserver: BreakpointObserver, private encryptDecrypt: EncryptDecryptService, private user: UserService, private dexieService: DexieService){}
  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.data  = JSON.parse(this.localstorage.getContainerData())?.data;
    this.securityKey  = JSON.parse(this.localstorage.getContainerData())?.securityKey;
    let userData = JSON.parse(this.localstorage.getUser());
    this.reminder = userData.reminder.toString();
    this.frequency = userData.frequency.toString();
    this.timer = userData.timer.toString();
  }

  // Listen for the event emitted by app-backup-sharing
  handleNextStepClicked() {
    if(this.recipient.length>0){
      if (!this.recipient.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
        this.openSnackBar('Please enter a valid email for recipient!');
        return ;
      }else if(this.recipient===this.localstorage.getEmail()){
        this.openSnackBar('The backup person should be another person not you!');
      }else{
          // Decide whether to show the app-backup-sharing or the content inside "content" div
          this.showBackupSharing = false;  // Set to false to show the content inside "content" div
      }
    }else{
      this.openSnackBar('To Continue you should enter an email!');
    }
  }
  
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  async next(){
    // here we add with backup person and expiration
      let decrypted;
      if(this.data.ownerEncryptionMode=="1"){  
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.localstorage.getMasterPassword()); 
        // here we add master password if public key is in the localstorage
        if(this.localstorage.getPublicKey()){
          let extractedPrivateKey = this.localstorage.getPrivateKey();
          let extractedPublicKey = this.localstorage.getPublicKey();
          let base64PublicKey = btoa(extractedPublicKey);
          let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
          let privateKeyEncrypted = await this.encryptDecrypt.encryptData(extractedPrivateKey, binarykey);

          let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted};
          this.user.updateProfile(data, this.localstorage.getEmail())
          .subscribe(async (res: any)=>{
            this.localstorage.setUser(JSON.stringify(res.user)); 
            this.localstorage.removePublicKey();      
          }); 
        }
      }else{
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
      }
      this.disabledButton = true;
      this.containerService.addContainer({...this.data, timer: this.timer, reminder: this.reminder, frequency: this.frequency, deadManSwitch: true, backup: {recipient: this.recipient, active: false, encryptionMode: this.encryptionMode, encryptionKey: this.encryptionKey, message: this.message}}).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any)=>{
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
              this.containerService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
            });
            this.disabledButton = false;
            this.openSnackBar('Container added successfully!');
            this.localstorage.removeContainerData();
            if(this.ownContainers.length==0){
              this.router.navigate(['home', 'start-migration']);
            }else{
              this.router.navigate(['home']);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.disabledButton = false;
            this.openSnackBar('Container cannot be created!');
          },
        });
  }

    async skip(){
    // here we add the container without backup
    let decrypted;
    if(this.data.ownerEncryptionMode=="1"){  
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.localstorage.getMasterPassword()); 
        if(this.localstorage.getPublicKey()){
          let extractedPrivateKey = this.localstorage.getPrivateKey();
          let extractedPublicKey = this.localstorage.getPublicKey();
          let base64PublicKey = btoa(extractedPublicKey);
          let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
          let privateKeyEncrypted = await this.encryptDecrypt.encryptData(extractedPrivateKey, binarykey);

          let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted};
          this.user.updateProfile(data, this.localstorage.getEmail())
          .subscribe(async (res: any)=>{
            this.localstorage.setUser(JSON.stringify(res.user)); 
            this.localstorage.removePublicKey();      
          }); 
        }
    }else{
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(JSON.parse(this.data.ownerKey).data), this.data.ownerEncryptionMode=="2" ? await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey) : this.securityKey.slice(0, 12));
    }
    this.disabledButton = true;
    this.containerService.addContainer({...this.data, backup: undefined}).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any)=>{
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
              this.containerService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
            });
            this.openSnackBar('Container added successfully!');
            this.localstorage.removeContainerData();
            this.disabledButton = false;
            if(this.ownContainers.length==0){
              this.router.navigate(['home', 'start-migration']);
            }else{
              this.router.navigate(['home']);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabledButton = false;
          },
        });
  }

  cancel(){
    if(this.data.ownerEncryptionMode=="1"){
      this.router.navigate(['home','container-encryption', 1]);
    } else if(this.data.recipients.length>0){
      this.router.navigate(['home','sharing-for-advanced']);
    } else if(this.data.ownerEncryptionMode=="2"){
      this.router.navigate(['home','container-encryption', 2]);
    } else if(this.data.ownerEncryptionMode=="3"){
      this.router.navigate(['home','container-encryption', 3]);
    }
  }
}
