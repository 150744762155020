import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.scss']
})
export class DownloadPageComponent implements OnInit {
  
  @ViewChild('drawer') public sidenav: MatSidenav;

  selectedTabIndex = 0;
  platform = 'Web';
  isLogged = false;
  constructor(private router: Router, private localstorage: LocalStorageService, private sidenavService: SidenavService, private meta: Meta, private titleService: Title, private authService: AuthService, private logsService: ActivityLogsService, private dexieService: DexieService) { 
    const token = this.localstorage.getToken();
    const userData = JSON.parse(this.localstorage.getUser());
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.logsService.logLogout(userData?.id).subscribe({
                next: (response) => {
                  this.isLogged = false;
                  this._cleanlocalstorage();                  
                },
                error: (error) => {
                  console.error('Error logging logout', error);
                }
              });
            });
          }
        });
      }
    }else{

          this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();  
          });
    }
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
    this.titleService.setTitle('Download SyFly | The Safest Password Manager');
    this.meta.updateTag({ name: 'description', content: "Download SyFly on all your devices and for the maximum security. Enhance your experience with our browser extension for autofill, password generation, and synchronization." });
    this.meta.updateTag({ property: 'og:title', content: 'Download SyFly | The Safest Password Manager' });
    this.meta.updateTag({ property: 'og:description', content: "Download SyFly on all your devices and for the maximum security. Enhance your experience with our browser extension for autofill, password generation, and synchronization." });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }
  
  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  navigateToLoginPage(){
    this.router.navigate(['./login', 0]);
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  navigateToDashboard(){
    this.router.navigate(['./home']);
  }

  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }

  navigateToContactUs(){
    this.router.navigate(['./contact-us']);
  }

  navigateToCustomerSupport(){
    this.router.navigate(['./customer-support']);
  }

  navigateToTutorials(){
    this.router.navigate(['./tutorials']);
  }

  toggleSidenav(){
    this.sidenav.toggle();
  }

  downloadChrome(){
    window.location.href = 'https://chrome.google.com/webstore/detail/syfly-password-manager/gpcepkflmhehhdjlhbmledpppmakjooj';
  }

  downloadFirefox(){
    window.location.href = 'https://addons.mozilla.org/fr/firefox/addon/syfly-password-manager';
  }

  downloadSafari(){
    window.location.href = 'https://apps.apple.com/tn/app/syfly-password-manager/id6449669133?mt=12';
  }

  downloadMacos(){
    window.location.href = 'https://apps.apple.com/tn/app/syfly/id6449225980';
  }

  downloadAndroid(){
    window.location.href = 'https://play.google.com/store/apps/details?id=com.syfly.io';
  }

  downloadEdge(){
    window.location.href = 'https://microsoftedge.microsoft.com/addons/detail/syfly-password-manager/idljhpffioklijdcghnladfcmafnbhhj';
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }
  
}
