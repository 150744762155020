import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ContainerService } from 'src/app/services/container/container.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-normal-sharing',
  templateUrl: './normal-sharing.component.html',
  styleUrls: ['./normal-sharing.component.scss']
})
export class NormalSharingComponent {

  recipients: any[] = [];
  recipientDraft = '';
  data: any;
  @Input() isNew = false;
  @Input() type = 1;
  @Input() securityKey = '';
  reciepientCtrl = new UntypedFormControl('');
  invalidRecipients = false;
  disabled = false;
  plan: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Output("backStep") backStep = new EventEmitter();
  @Output() securityKeyChange = new EventEmitter<string>();

  disabledButton = false;

  get ownContainers(){
    return this.containerService.ownContainers;
  }

  constructor( private _snackBar: MatSnackBar, private containerService: ContainerService, private router: Router, private localstorage: LocalStorageService, private encryptDecrypt: EncryptDecryptService, private user: UserService, private dexieService: DexieService ) {
  }

  ngOnInit(): void {
    this.data  = JSON.parse(this.localstorage.getContainerData())?.data;
    this.plan = JSON.parse(this.localstorage.getPlan());
  }
  
  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
        this.openSnackBar('Please enter a valid email for recipient!');
        this.invalidRecipients=true;
        this.reciepientCtrl.setValue('');
        return ;
      }
      if(value===this.localstorage.getEmail()){
        this.openSnackBar('You cannot add yourself as a recipient!');
        this.invalidRecipients=true;
        this.reciepientCtrl.setValue('');
        return;
      }
      if(this.recipients.findIndex((e)=>e.email==value)!=-1){
        this.openSnackBar('Already added as a recipient!');
        this.invalidRecipients=true;
        this.reciepientCtrl.setValue('');
        return;
      }
      if (this.plan.recipients !== 'unlimited') {

        if (this.recipients.length + 1 > Number(this.plan.recipients)) {
          this.openSnackBar(
            `You can only add ${this.plan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!`
          );
        } else {
          this.recipients.push({ email: value }); 
        }
      } else {
        this.recipients.push({ email: value }); 
      }
    }

    event.chipInput.clear();

    this.reciepientCtrl.setValue('');
  }
  remove(reciepient: any): void {
    const index = this.recipients.indexOf(reciepient);

    if (index >= 0) {
      this.recipients.splice(index, 1);
    }
  }

  onChange(){
    this.reciepientCtrl.valueChanges.subscribe((value)=> this.recipientDraft = value.trim());
  }
  
  verifyRecipientsDraft(){
    if (!this.recipientDraft.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)){ 
      this.openSnackBar('Please enter a valid email for recipient!');
      return;
    }
    if(this.recipientDraft===this.localstorage.getEmail()){
      this.openSnackBar('You cannot add yourself as a recipient!');
      this.reciepientCtrl.setValue('');
      return;
    }
    if(this.recipients.findIndex((e)=>e.email==this.recipientDraft)!=-1){
      this.openSnackBar('Already added as a recipient!');
      this.reciepientCtrl.setValue('');
      return;
    }
    if (this.plan.recipients !== 'unlimited') {

      if (this.recipients.length + 1 > Number(this.plan.recipients)) {
        this.openSnackBar(
          `You can only add ${this.plan.recipients} recipients, if you wanna add more recipients, please upgrade your plan!`
        );
        this.reciepientCtrl.setValue('');
        return;
      } else {
        this.recipients.push({ email: this.recipientDraft });
        this.reciepientCtrl.setValue('');
      }
    } else {
      this.recipients.push({ email: this.recipientDraft});
      this.reciepientCtrl.setValue('');
    }
  }
  
  async next(){
    if(this.recipientDraft.length>0) this.verifyRecipientsDraft();
    if(this.recipients.length===0){
      this.openSnackBar('You should add at least one recipient!');

    }else{
      if(this.type==1){
        // ! here we gonna change the logic of the function to save the data
        this.disabledButton = true;
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.localstorage.getMasterPassword()));
        let decrypted = await this.encryptDecrypt.decryptKey(key, this.localstorage.getMasterPassword());
        // this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: JSON.stringify(this.encryptDecrypt.toBuffer(decrypted)), recipients: this.recipients.map((el) => el.email)}}));
        // this.router.navigate(['home', 'backup-person']);
        if(this.localstorage.getPublicKey()){  
          let extractedPrivateKey = this.localstorage.getPrivateKey();
          let extractedPublicKey = this.localstorage.getPublicKey();
          let base64PublicKey = btoa(extractedPublicKey);
          let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
          let privateKeyEncrypted = await this.encryptDecrypt.encryptData(extractedPrivateKey, binarykey);
          let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted};
          this.user.updateProfile(data, this.localstorage.getEmail())
            .subscribe(async (res: any)=>{
              this.localstorage.setUser(JSON.stringify(res.user)); 
              this.localstorage.removePublicKey();      
            }); 
        }

        this.containerService.addContainer({...this.data, ownerKey: JSON.stringify(key), recipientsKey: JSON.stringify(this.encryptDecrypt.toBuffer(decrypted)), recipients: this.recipients.map((el) => el.email), backup: undefined}).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any)=>{
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
              this.containerService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
            });
            this.openSnackBar('Container added successfully!');
            this.localstorage.removeContainerData();
            this.disabledButton = false;
            if(this.ownContainers.length==0){
              this.router.navigate(['home', 'start-migration']);
            }else{
              this.router.navigate(['home']);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabledButton = false;
          },
        });

      }else if(this.type==2){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey)));
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
        this.router.navigate(['home', 'sharing-for-advanced']);
      }else if(this.type==3){
        let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.securityKey.slice(0, 12)));
        this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
        this.router.navigate(['home', 'sharing-for-advanced']);
      }

    }

  }

  previous(){
    if(this.type==1){
        this.router.navigate(['home', 'create-container']);
    }else{
      this.backStep.emit();
    }
  }
  // ! here we gonna change the logic of the skip function to save the data
  // async skip(){ 
  //     if(this.type==1){
  //       let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.localstorage.getMasterPassword()));
  //       let decrypted = await this.encryptDecrypt.decryptKey(key, this.localstorage.getMasterPassword());
  //       this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: JSON.stringify(this.encryptDecrypt.toBuffer(decrypted)), recipients: this.recipients.map((el) => el.email)}}));
  //       this.router.navigate(['home', 'backup-person']);
  //     }else if(this.type==2){
  //       let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey)));
  //       this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
  //       this.router.navigate(['home', 'backup-person']);
  //     }else if(this.type==3){
  //       let key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.securityKey.slice(0, 12)));
  //       this.localstorage.setContainerData(JSON.stringify({ data: {...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: this.recipients.map((el) => el.email)}, securityKey: this.securityKey}));
  //       this.router.navigate(['home', 'backup-person']);
  //     }
  // }

  async skip(){
    this.disabledButton = true;
    let decrypted;
    let key;
    if(this.type==1){
        key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.localstorage.getMasterPassword()));
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(key), this.localstorage.getMasterPassword()); 
      }else if(this.type==2){
        key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey)));
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(key), await this.encryptDecrypt.getPBKDF2Hash1M(this.securityKey));
      }else if(this.type==3){
        key = this.encryptDecrypt.toBuffer(await this.encryptDecrypt.encryptKey(await this.encryptDecrypt.getRandom(), this.securityKey.slice(0, 12)));
        decrypted = await this.encryptDecrypt.decryptKey(new Uint8Array(key), this.securityKey.slice(0, 12));
    }
    if(this.type==1 && this.localstorage.getPublicKey()){  
      let extractedPrivateKey = this.localstorage.getPrivateKey();
      let extractedPublicKey = this.localstorage.getPublicKey();
      let base64PublicKey = btoa(extractedPublicKey);
      let binarykey = this.encryptDecrypt.bufferToBinary(this.encryptDecrypt.getKeySupportedLength(this.localstorage.getMasterPassword()));
      let privateKeyEncrypted = await this.encryptDecrypt.encryptData(extractedPrivateKey, binarykey);
      let data = {publicKey: base64PublicKey, privateKey: privateKeyEncrypted};
      this.user.updateProfile(data, this.localstorage.getEmail())
        .subscribe(async (res: any)=>{
          this.localstorage.setUser(JSON.stringify(res.user)); 
          this.localstorage.removePublicKey();      
        }); 
    }
    this.containerService.addContainer({...this.data, ownerKey: JSON.stringify(key), recipientsKey: null, recipients: [], backup: undefined}).subscribe({
          next: (res: any) => {
            this.dexieService.getOwnContainers().then((data: any)=>{
              let binary = this.encryptDecrypt.bufferToBinary(decrypted);
              this.dexieService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
              this.containerService.setOwnContainers([...data, {...this.containerService.setContainer(res.container), decryptedOwnerKey: binary, decrypted: true}]);
            });
            this.openSnackBar('Container added successfully!');
            this.localstorage.removeContainerData();
            this.disabledButton = false;
            if(this.ownContainers.length==0){
              this.router.navigate(['home', 'start-migration']);
            }else{
              this.router.navigate(['home']);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.openSnackBar('Container cannot be created!');
            this.disabledButton = false;
          },
        });
  }
}
