import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { LocalStorageService } from '../../services/localstorage/localstorage.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
  selector: 'app-first-steps-create-container',
  templateUrl: './first-steps-create-container.component.html',
  styleUrls: ['./first-steps-create-container.component.scss']
})
export class FirstStepsCreateContainerComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper
  isMobile: boolean = false;
  // @Output() type = 0;
  @Output() icon = 1;
  @Output() ownerEncryptionMode = '1';
  @Output() description = '';
  @Output() preview = 'assets/images/predefined-icons/lamp.svg';
  @Output() name = '';
  @Output() src = 'assets/images/predefined-icons/lamp.svg';
  // @Output() types = [
  //   {name: 'Social Media', icon: 832, src: 'assets/images/predefined-icons/social-media.svg'},
  //   {name: 'Work', icon: 833, src: 'assets/images/predefined-icons/work.svg'},
  //   {name: 'Home', icon: 834, src: 'assets/images/predefined-icons/home.svg'},
  //   {name: 'Emails', icon: 835, src: 'assets/images/predefined-icons/emails.svg'},
  //   {name: 'Cards', icon: 836, src: 'assets/images/predefined-icons/credit-cards.svg'},
  //   {name: 'Bank Accounts', icon: 837, src: 'assets/images/predefined-icons/bank.svg'},
  // ];
  get ownContainers(){
    return this.containerService.ownContainers;
  }

  constructor(private localstorage: LocalStorageService, private breakpointObserver: BreakpointObserver, private containerService: ContainerService, private router: Router, private _snackBar: MatSnackBar){}
  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 767px)']).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  backType(){
    this.router.navigate(['home']);
  }

  // confirmType(){
  //   this.name = this.type!=-1 ? this.types[this.type]['name'] :  '';
  //   this.icon = this.type!=-1 ? this.types[this.type]['icon'] :  1;
  //   this.src = this.type!=-1 ? this.types[this.type]['src'] :  'assets/images/predefined-icons/lamp.svg';
  //   this.preview = this.type!=-1 ? this.types[this.type]['src'] :  'assets/images/predefined-icons/lamp.svg';
  //   let exists = this.ownContainers.filter((e: any)=>e.name.includes(this.name));
  //   if(exists.length>0) this.name = this.name + ` ${exists.length + 1}`;
  //   this.stepper.next();
  // }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}
  
  confirmData(){
    if(this.name.trim().length===0&& this.description.trim().length===0){
        return this.openSnackBar(`Please enter at least a name for your container!`);

    }else{
      let index = this.ownContainers.findIndex((e: any)=>(e.name).trim()===(this.name).trim());
      if(index==-1){
        this.stepper.next();
      }else{
        return this.openSnackBar(`You already have a container named ${this.name}, please use another name!`);
      }
    }
  }
  async next(){
    let plan = JSON.parse(this.localstorage.getPlan());
    let user = JSON.parse(this.localstorage.getUser());
    let usedMemory = `{"memory": "0", "unit": "${JSON.parse(plan.memory).unit}"}`;

    if(this.ownerEncryptionMode == "1"){

      if(user.privateKey && user.publicKey && this.localstorage.getMasterPassword()){
        // here if the user has a master password and he has it saved locally (in case he used it before deciding to add a container)
        const data= {name: this.name, icon: this.icon, description: this.description, owner: this.localstorage.getEmail(), passwords: [], notebooks: [], deadManSwitch: false, usedMemory, ownerEncryptionMode: "1", recipientsEncryptionMode: '-'}
        this.localstorage.setContainerData(JSON.stringify({data}));
      }else if(user.privateKey!=null && user.publicKey!=null && !this.localstorage.getMasterPassword()){
        // here if the user has a master password but he didn't use it in the current session, so he needs to verify it
        const data= {name: this.name, icon: this.icon, description: this.description, owner: this.localstorage.getEmail(), passwords: [], notebooks: [], deadManSwitch: false, usedMemory, ownerEncryptionMode: "1", recipientsEncryptionMode: '-'}
        this.localstorage.setContainerData(JSON.stringify({data, new: false})); 
      }else{
        // here the user don't have a master password, which means it is his first time to create a standard secure container
        const data= {name: this.name, icon: this.icon, description: this.description, owner: this.localstorage.getEmail(), passwords: [], notebooks: [], deadManSwitch: false, usedMemory, ownerEncryptionMode: "1", recipientsEncryptionMode: '-'}
        this.localstorage.setContainerData(JSON.stringify({data, new: true}));
      }
      this.router.navigate(['home','container-encryption', 1]);

    }else if(this.ownerEncryptionMode == "2"){ // passphrase
        const data= {name: this.name, icon: this.icon, description: this.description, owner: this.localstorage.getEmail(), passwords: [], notebooks: [], deadManSwitch: false, usedMemory, ownerEncryptionMode: "2", recipientsEncryptionMode: ''}
        this.localstorage.setContainerData(JSON.stringify({data}));
        this.router.navigate(['home','container-encryption', 2]);

    }else if(this.ownerEncryptionMode == "3"){ // hardware key
        const data= {name: this.name, icon: this.icon, description: this.description, owner: this.localstorage.getEmail(), passwords: [], notebooks: [], deadManSwitch: false, usedMemory, ownerEncryptionMode: "3", recipientsEncryptionMode: ''}
        this.localstorage.setContainerData(JSON.stringify({data}));
        this.router.navigate(['home','container-encryption', 3]);

    }
    
  }
}
