import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ContainerService } from 'src/app/services/container/container.service';
import { MarkdownService } from 'ngx-markdown';
import TurndownService from 'turndown';
import Quill from 'quill';
import { HttpErrorResponse } from '@angular/common/http';
import { ContainerNoteService } from 'src/app/services/container-note/container-note.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt/encrypt-decrypt.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { DexieService } from 'src/app/services/dexie/dexie.service';

@Component({
  selector: 'app-notebooks-details',
  templateUrl: './notebooks-details.component.html',
  styleUrls: ['./notebooks-details.component.scss']
})
export class NotebooksDetailsComponent implements OnInit{
  @ViewChild('start') start: any;
  @ViewChild('markdownTextarea') myTextarea!: ElementRef;

  index: any;
  type: any;
  isEdit = false;
  changesDetected = false;
  notebookIndex = 0;
  notebook: any;
  container: any;
  selectedIndex = 0;
  markdownToolbar = '';
  noteName = '';
  markdownData = '';
  wysiwygData = { data: '',  deltaJson: [] };
  initialNoteName = '';
  initialMarkdownData = '';
  initialWysiwygData= { data: '',  deltaJson: [] };
  isMarkdown = true;
  notes = [];
  modules: {};
  userPlan: any;
  scale = {'B': 1, 'KB': 1000, 'MB': 1000000, 'GB': 1000000000, 'TB': 1000000000000};
  user: any = {firstName: '', lastName: '', profilePicture: '' , email: '', id: 0};
  
  get dark(){
    return this.theme.dark;
  }

  get ownContainers(): any{
      return this.containerService.ownContainers;
  }

  get sharedContainers(): any{
      return this.containerService.sharedContainers;
  }

  get deadManSwitchContainers(): any{
      return this.containerService.deadManSwitchContainers;
  } 
  constructor(private route: ActivatedRoute, private theme: ThemeService, private location: Location, private _snackBar: MatSnackBar, private markdownService: MarkdownService, private notebookService: ContainerNoteService, private containerService: ContainerService, private encryptDecrypt: EncryptDecryptService, private localstorage: LocalStorageService, private dexieService: DexieService){
    this.userPlan = {...JSON.parse(this.localstorage.getPlan()), memory: {...JSON.parse(JSON.parse(this.localstorage.getPlan()).memory), memory: Number(JSON.parse(JSON.parse(this.localstorage.getPlan()).memory).memory)}};
    this.user =  {firstName: JSON.parse(this.localstorage.getUser())['firstName'] ?? '', lastName: JSON.parse(this.localstorage.getUser())['lastName'] ?? '', profilePicture: JSON.parse(this.localstorage.getUser())['profilePicture'], email:  JSON.parse(this.localstorage.getUser())['email'], id: JSON.parse(this.localstorage.getUser())['id'] };
    this.type = this.route.snapshot.paramMap.get('type');
    this.index = Number(this.route.snapshot.paramMap.get('index'));
    this.notebookIndex = Number(this.route.snapshot.paramMap.get('notebook'));
    this.setData();
  }

  ngOnInit(){
    this.setData();
  }
  
  isString(value: any): boolean {
  return typeof value === 'string';
}

  setData(){
    if(this.type=='own'){
      this.container = this.ownContainers[this.index] ?? {};
      this.notebook = this.ownContainers[this.index]?.decryptedNotebooks[this.notebookIndex] ?? {};
      this.notes = this.ownContainers[this.index]?.decryptedNotebooks[this.notebookIndex]?.notes ?? [];
    }else if(this.type=='shared'){
      this.container = this.sharedContainers[this.index] ?? {};
      this.notebook = this.sharedContainers[this.index]?.decryptedNotebooks[this.notebookIndex] ?? {};
      this.notes = this.sharedContainers[this.index]?.decryptedNotebooks[this.notebookIndex]?.notes ?? [];
    }else{
      this.container = this.deadManSwitchContainers[this.index] ?? {};
      this.notebook = this.deadManSwitchContainers[this.index]?.decryptedNotebooks[this.notebookIndex] ?? {};
      this.notes = this.deadManSwitchContainers[this.index]?.decryptedNotebooks[this.notebookIndex]?.notes ?? [];
    }
  }

  formatBold(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}**${selectedText.length>0 ? selectedText : 'strong text'}**${rightText}`;
  }

  formatItalic(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}*${selectedText.length>0 ? selectedText : 'italic text'}*${rightText}`;
  }

  formatHeader(level: number, selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText} ${leftText.endsWith('\n') ? '' : '\n'}${Array.from({length: level}, ()=>'#').join('')} ${selectedText.length>0 ? selectedText : 'title'}${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatHR(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}***${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatNumberedList(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let allRows = leftText.split('\n').filter((e)=>e.length>0);
    let orderedList = 0;
    if(allRows.length>0){
      let lastSentence = allRows[allRows.length-1];
      let lastSentenceParts = lastSentence.split('.');
      if(lastSentenceParts.length>0 && !Number.isNaN(Number(lastSentenceParts[0]))){
          orderedList = Number(lastSentenceParts[0]);
      }
    }
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}${orderedList+1}. ${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatBulletedList(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}- ${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;

  }

  formatCheckbox(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}- [ ] checkbox option ${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatCode(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}\`\`\`${selectedText.length>0 ? selectedText : '<html>code block</html>'}\`\`\`${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatQuote(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    let selectedText = this.markdownData.substring(selectionStart, selectionEnd).trim();
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}> ${selectedText.length>0 ? selectedText : 'blockquote'}${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  formatInsertLink(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}[title](https://link.com)${rightText}`;
  }

  formatInsertPhoto(selectionStart: number, selectionEnd: number){
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionEnd);
    this.markdownData = `${leftText}${leftText.endsWith('\n') ? '' : '\n'}![image alt](image path)${rightText.startsWith('\n') ? '' : '\n'}${rightText}`;
  }

  switchMarkdownToolbarClick(value: string){
    this.markdownToolbar = value;
    const textarea: HTMLTextAreaElement = this.myTextarea.nativeElement;
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    switch(value){
      case 'bold': this.formatBold(selectionStart, selectionEnd); break;
      case 'italic': this.formatItalic(selectionStart, selectionEnd); break;
      case 'orderedList': this.formatNumberedList(selectionStart, selectionEnd); break;
      case 'unorderedList': this.formatBulletedList(selectionStart, selectionEnd); break;
      case 'checkbox': this.formatCheckbox(selectionStart, selectionEnd); break;
      case 'code': this.formatCode(selectionStart, selectionEnd); break;
      case 'quote': this.formatQuote(selectionStart, selectionEnd); break;
      case 'h1': this.formatHeader(1, selectionStart, selectionEnd); break;
      case 'h2': this.formatHeader(2, selectionStart, selectionEnd); break;
      case 'h3': this.formatHeader(3, selectionStart, selectionEnd); break;
      case 'h4': this.formatHeader(4, selectionStart, selectionEnd); break;
      case 'h5': this.formatHeader(5, selectionStart, selectionEnd); break;
      case 'h6': this.formatHeader(6, selectionStart, selectionEnd); break;
      case 'hr': this.formatHR(selectionStart, selectionEnd);  break;
      case 'link': this.formatInsertLink(selectionStart, selectionEnd); break;
      case 'image': this.formatInsertPhoto(selectionStart, selectionEnd); break;
      default: break;
    }
  }

  goBack(): void {
    this.location.back();
  }

  toggleSidebar(){
      this.start.toggle();
  }
  
  toggleEditor() {
    this.isMarkdown = !this.isMarkdown;
  }
  
  async onMarkdownChange(event: any){  
    this.wysiwygData.data = await this.markdownService.parse(this.markdownData);
    if(this.initialNoteName!=this.noteName || this.initialMarkdownData!=this.markdownData || this.initialWysiwygData.deltaJson!=this.wysiwygData.deltaJson){
      this.changesDetected = true;
    }else{
      this.changesDetected = false;
    }
  }

  insertAdditionalNewLine($event){
    const textarea: HTMLTextAreaElement = this.myTextarea.nativeElement;
    const selectionStart = textarea.selectionStart;
    let leftText = this.markdownData.substring(0, selectionStart);
    let rightText = this.markdownData.substring(selectionStart);
    this.markdownData = `${leftText}${leftText.endsWith('\n') && rightText.startsWith('\n') ? '' : (!leftText.endsWith('\n') && !rightText.startsWith('\n') ? '\n\n' : '\n')}${rightText}`;
  }

  onWysiwygChange(event: any){
    this.wysiwygData.deltaJson = event.content['ops'];
    this.markdownData = this.praseHtmlToMd(this.wysiwygData.data);
    if(this.initialNoteName!=this.noteName || this.initialMarkdownData!=this.markdownData || this.initialWysiwygData.deltaJson!=this.wysiwygData.deltaJson){
      this.changesDetected = true;
    }else{
      this.changesDetected = false;
    }
  }

  nameChanged($event){
    if(this.initialNoteName!=this.noteName || this.initialMarkdownData!=this.markdownData || this.initialWysiwygData.deltaJson!=this.wysiwygData.deltaJson){
      this.changesDetected = true;
    }else{
      this.changesDetected = false;
    }
  }

  addNote(){
    this.selectedIndex = 0;
    this.noteName = '';
    this.markdownData = '';
    this.wysiwygData = { data: '', deltaJson: [] };
    this.initialNoteName = '';
    this.initialMarkdownData = '';
    this.initialWysiwygData= { data: '',  deltaJson: [] };
  }

  selectedTabChange(event: { index: number; }){
    this.setData();
    this.selectedIndex = event.index;
    if(event.index!=0){
      this.isEdit = true;
      this.noteName = this.notes[event.index-1].title;
      this.wysiwygData = this.notes[event.index-1].wysiwygData;
      this.markdownData = this.notes[event.index-1].markdownData;
      this.initialNoteName = this.notes[event.index-1].title;
      this.initialWysiwygData = this.notes[event.index-1].wysiwygData;
      this.initialMarkdownData = this.notes[event.index-1].markdownData;
      this.changesDetected = false;
    }else{
      this.isEdit = false;
      this.changesDetected = false;
      this.noteName = '';
      this.markdownData = '';
      this.wysiwygData = { data: '',  deltaJson: [] };
      this.initialNoteName = '';
      this.initialMarkdownData = '';
      this.initialWysiwygData = { data: '',  deltaJson: [] };
    }
  }

  selectNote(index: number){
    this.setData();
    this.isEdit = true;
    this.changesDetected = false;
    this.selectedIndex = index + 1;
    this.noteName = this.notes[index].title;
    this.wysiwygData = this.notes[index].wysiwygData;
    this.markdownData = this.notes[index].markdownData;
    this.initialNoteName = this.notes[index].title;
    this.initialWysiwygData = this.notes[index].wysiwygData;
    this.initialMarkdownData = this.notes[index].markdownData;
    // this.markdownService.reload();
  }

  deleteNote(){
    if(this.selectedIndex != 0){
      this.setData();
      // this.moveToTrashNoteFromDatabase();
      this.deleteNoteFromDatabase();
    }else{
      this.openSnackBar('Please select a note to delete!');
    }
  }

  saveNote(){
    this.setData();
    if(this.noteName.trim().length==0) {
        this.openSnackBar('Note name is required!');

    }else if (this.wysiwygData.data.trim().length==0){
        this.openSnackBar('Note cannot be empty!');

    }else if(!this.changesDetected){
        this.openSnackBar('No changes to save!');
        
    }else{
      if(this.selectedIndex==0){
        this.addNoteToDatabase(this.noteName, {
                created_at: (new Date()).toDateString(),
                wysiwygData: {data: this.wysiwygData.data, deltaJson: this.parsHtmlToDelta(this.wysiwygData.data)['ops']},
                markdownData: this.praseHtmlToMd(this.wysiwygData.data)
              });
      }else{
        this.updateNote(this.noteName, {
              created_at: (new Date()).toDateString(),
              wysiwygData: {data: this.wysiwygData.data, deltaJson: this.parsHtmlToDelta(this.wysiwygData.data)['ops']},
              markdownData: this.praseHtmlToMd(this.wysiwygData.data)
            });
      }
    }
  }

  praseHtmlToMd(data: string){
    let turndownService = new TurndownService();
      turndownService.addRule('fenceAllPreformattedText', {
          filter: ['pre'],
          replacement: function (content: any, node: { firstChild: { textContent: string; }; }, options: { fence: string; }) {
            return (
              '\n\n' + options.fence + '\n' +
              node.firstChild.textContent +
              '\n' + options.fence + '\n\n'
            )
          },
        });
    return turndownService.turndown(data);
  }

  parsHtmlToDelta(html: string) {
    const div = document.createElement('div');
    div.setAttribute('id', 'htmlToDelta');
    div.innerHTML = `<div id="quillEditor" style="display:none">${html}</div>`;
    document.body.appendChild(div);
    const quill = new Quill('#quillEditor');
    const delta = quill.getContents();
    document.getElementById('htmlToDelta').remove();
    return delta;
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  deleteNoteFromDatabase(){
      let containerID = this.container.id;
      let notebookID = this.container.decryptedNotebooks[this.notebookIndex].id;
      this.notebookService.deleteNote(containerID, notebookID, this.container.decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1].id)
                  .subscribe({
                    next: async (result: any)=>{
                      if(this.type=='own'){
                          this.dexieService.getOwnContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            decryptedNotebooks[this.notebookIndex].notes.splice(this.selectedIndex-1, 1);
                            let size = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].size = {...size, memory: Number(size.memory)}
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setOwnContainers(d);
                            this.containerService.setOwnContainers(d);
                            this.openSnackBar('Note deleted successfully!');
                          });

                      }else if(this.type=='shared'){
                          this.dexieService.getSharedContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            decryptedNotebooks[this.notebookIndex].notes.splice(this.selectedIndex-1, 1);
                            let size = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].size = {...size, memory: Number(size.memory)}
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            this.openSnackBar('Note deleted successfully!');
                          });
                      }else{
                          this.dexieService.getDeadManSwitchContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            decryptedNotebooks[this.notebookIndex].notes.splice(this.selectedIndex-1, 1);
                            let size = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].size = {...size, memory: Number(size.memory)}
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            this.openSnackBar('Note deleted successfully!');
                          });
                      }
                    }, 
                    error: (error: HttpErrorResponse)=>{
                      this.openSnackBar('Error while updating the note!');
                    }
                  });
  }

  moveToTrashNoteFromDatabase(){
      let containerID = this.container.id;
      let notebookID = this.container.decryptedNotebooks[this.notebookIndex].id;
      this.notebookService.moveToTrashNote(containerID, notebookID, this.container.decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1].id)
                  .subscribe({
                    next: async (result: any)=>{
                      if(this.type=='own'){
                          this.dexieService.getOwnContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            decryptedNotebooks[this.notebookIndex].notes.splice(this.selectedIndex-1, 1);
                            d[this.index] = {...this.container, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setOwnContainers(d);
                            this.containerService.setOwnContainers(d);
                            this.openSnackBar('Note moved to trash!');
                          });

                      }else if(this.type=='shared'){
                          this.dexieService.getSharedContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            decryptedNotebooks[this.notebookIndex].notes.splice(this.selectedIndex-1, 1);
                            d[this.index] = {...this.container, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            this.openSnackBar('Note moved to trash!');
                          });
                      }else{
                          this.dexieService.getDeadManSwitchContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            decryptedNotebooks[this.notebookIndex].notes.splice(this.selectedIndex-1, 1);
                            d[this.index] = {...this.container, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            this.openSnackBar('Note moved to trash!');
                          });
                      }
                    }, 
                    error: (error: HttpErrorResponse)=>{
                      this.openSnackBar('Error while deleting the note!');
                    }
                  });
  }

  async updateNote(title: any, data: any){
    let containerID = this.container.id;
    let notebookID = this.container.decryptedNotebooks[this.notebookIndex].id;
    let encryption = '';
    if(this.type=='shared') {
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedRecipientKey);
    }else if(this.type=='own'){
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedOwnerKey);
    }else{
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedBackUpPersonKey);
    }

    const { size, restStorage } = this.calculateMemory({...data, title});

    if(restStorage > 0 ){
          this.notebookService.updateNote(title, encryption, size, containerID, notebookID, this.container.decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1].id)
                  .subscribe({
                    next: async (result: any)=>{
                      if(this.type=='own'){
                          this.dexieService.getOwnContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            let notebookSize = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1] = {title, ...decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1], ...data, size};
                            decryptedNotebooks[this.notebookIndex].size = {...notebookSize, memory: Number(notebookSize.memory)};
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setOwnContainers(d);
                            this.containerService.setOwnContainers(d);
                            this.openSnackBar('Note updated successfully!');
                          });

                      }else if(this.type=='shared'){
                          this.dexieService.getSharedContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            let notebookSize = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1] = {title, ...decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1], ...data, size};
                            decryptedNotebooks[this.notebookIndex].size = {...notebookSize, memory: Number(notebookSize.memory)};
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            this.openSnackBar('Note updated successfully!');
                          });
                      }else{
                          this.dexieService.getDeadManSwitchContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            let notebookSize = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1] = {title, ...decryptedNotebooks[this.notebookIndex].notes[this.selectedIndex-1], ...data, size};
                            decryptedNotebooks[this.notebookIndex].size = {...notebookSize, memory: Number(notebookSize.memory)};
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            this.openSnackBar('Note updated successfully!');
                          });
                      }
                    }, 
                    error: (error: HttpErrorResponse)=>{
                      this.openSnackBar('Error while updating the note!');
                    }
                  });
        
                } else{
                  this.openSnackBar('Note cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
                }
  }

  async addNoteToDatabase(title: any, data: any){
    let containerID = this.container.id;
    let notebookID =  this.container.decryptedNotebooks[this.notebookIndex].id;
    let encryption = '';
    if(this.type=='shared') {
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedRecipientKey);
    }else if(this.type=='own'){
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedOwnerKey);
    }else{
        encryption = await this.encryptDecrypt.encryptData(JSON.stringify(data), this.container.decryptedBackUpPersonKey);
    }

    const { size, restStorage } = this.calculateMemory({...data, title});

    if(restStorage > 0 ){
          this.notebookService.addNote(title, encryption, size, containerID, notebookID, this.user.id)
                  .subscribe({
                    next: async (result: any)=>{
                      if(this.type=='own'){
                          this.dexieService.getOwnContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            let notebookSize = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].notes.push({title: title, ...data, size, id: result.note});
                            decryptedNotebooks[this.notebookIndex].size = {...notebookSize, memory: Number(notebookSize.memory)}
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setOwnContainers(d);
                            this.containerService.setOwnContainers(d);
                            this.openSnackBar('Note added successfully!');
                          });

                      }else if(this.type=='shared'){
                          this.dexieService.getSharedContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            let notebookSize = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].notes.push({title: title, ...data, size, id: result.note});
                            decryptedNotebooks[this.notebookIndex].size = {...notebookSize, memory: Number(notebookSize.memory)}
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setSharedContainers(d);
                            this.containerService.setSharedContainers(d);
                            this.openSnackBar('Note added successfully!');
                          });
                      }else{
                          this.dexieService.getDeadManSwitchContainers().then((da: any)=>{
                            let d = da;
                            let decryptedNotebooks =  this.container.decryptedNotebooks;
                            let notebookSize = JSON.parse(result.notebookSize);
                            let usedMemory = JSON.parse(result.usedMemory);
                            decryptedNotebooks[this.notebookIndex].notes.push({title: title, ...data, size, id: result.note});
                            decryptedNotebooks[this.notebookIndex].size = {...notebookSize, memory: Number(notebookSize.memory)}
                            d[this.index] = {...this.container, usedMemory: {...usedMemory, memory: Number(usedMemory.memory)}, decryptedNotebooks: decryptedNotebooks};
                            this.dexieService.setDeadManSwitchContainers(d);
                            this.containerService.setDeadManSwitchContainers(d);
                            this.openSnackBar('Note added successfully!');
                          });
                      }
                    }, 
                    error: (error: HttpErrorResponse)=>{
                      this.openSnackBar('Error while adding the note!');
                    }
                  });
        
                } else{
                  this.openSnackBar('Note cannot be edited! You reached the limit of your storage! Please upgrade your account to save more data with us!');
                }
  }

  calculateMemory(data: any){
    let memory = Buffer.byteLength(JSON.stringify({...data}));
    let size = {};

    if(memory<999){
      size = {memory, unit: 'B'};

    }else if((memory>=1000) && (999999>memory)){
      size = {memory: (memory/1000), unit: 'KB'};

    }else if((memory>=1000000) && (999999999>memory)){
      size = {memory: (memory/1000000), unit: 'MB'};

    }else if((memory>=1000000000) && (999999999999>memory)){
      size = {memory: (memory/1000000000), unit: 'GB'};

    }else if((memory>=1000000000000) && (999999999999999>memory)){
      size = {memory: (memory/1000000000000), unit: 'TB'};

    } 

    const totalMemory = this.userPlan.memory.memory * this.scale[this.userPlan.memory.unit];
    let restStorage = totalMemory - memory;
    this.ownContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.sharedContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    this.deadManSwitchContainers.forEach((container: any) => {
      restStorage = restStorage - (container.usedMemory.memory * this.scale[container.usedMemory.unit])
    });
    return {size, restStorage}
  }

}
