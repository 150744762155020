import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  isLogged = false;
  expanded = [true, false, false];
  constructor(private router: Router, private localstorage: LocalStorageService, private authService: AuthService, private dexieService: DexieService) {
    const token = this.localstorage.getToken();
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        const userData = JSON.parse(this.localstorage.getUser());
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();  
            });
          }
        });
      }
    }else{

          this.dexieService.clearDB().then(() => {
              this.isLogged = false;
              this._cleanlocalstorage();  
          });
    }
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  toggleExpanded(index: any){
    this.expanded = [false, false, false];
    this.expanded[index] = true;
  }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  navigateToLoginPage(){
    this.router.navigate(['./login', 0]);
  }

  navigateToDashboard(){
    this.router.navigate(['./home']);
  }
}
