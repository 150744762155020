import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-pricing-plan',
  templateUrl: './pricing-plan.component.html',
  styleUrls: ['./pricing-plan.component.scss']
})

export class PricingPlanComponent {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    margin: 10,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      768: {
        items:2
      },
      1024: {
        items: 3
      },
      1200:{
        items:4
      },
      1400:{
        items:5
      },
    },
    nav: false
  }

pricingPlans = [
  {
    id: 2,
    type:"Free",
    price:0,
    monthly: 0,
    recipients:"02",
    containers:"5",
    storage:"50MB"
  },
  {
    id: 3,
    type:"Silver",
    price: 59.99,
    monthly: 4.99,
    recipients:"10",
    containers:"Unlimited",
    storage:"500MB"

  },
  {
    id: 4,
    type:"Gold",
    price: 89.99,
    monthly: 7.49,
    recipients:"20",
    containers:"Unlimited",
    storage:"5GB"

  },
  {
    id: 5,
    type:"Platinum",
    price: 214.99,
    monthly: 17.92,
    recipients:"Unlimited",
    containers:"Unlimited",
    storage:"50GB"
  }
]
  constructor(private router: Router){

  }

  getStartedPlan(plan: any){
    this.navigateToRegisterPage(plan);
  }

  navigateToRegisterPage(plan){
    this.router.navigate(['./register', plan.id]);
  }
}
