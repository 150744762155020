import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanSettingsService {

  constructor(private http: HttpClient) { }

  newSubscription(userId: any, planId: any, paymentMode: any){
    return this.http.post(`${environment.apiUrl}/plan-settings/new-subscription`, {userId, planId, paymentMode, isDesktop: false});
  }

  cancelSubscription(userId: any){
    return this.http.post(`${environment.apiUrl}/plan-settings/cancel-subscription`, {userId});
  }

  updateSubscription(userId: any, planId: any){
    return this.http.post(`${environment.apiUrl}/plan-settings/change-subscription`, {userId, planId, isDesktop: false});
  }
}
