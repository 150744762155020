<div>
    <div class="content mt-3">
      
      <div
        class="mb-[30px]"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="10px"
      >
          <div class="group" fxFlex>
              <svg class="icon mt-3" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
              <input placeholder="Search" type="search" class="input" [(ngModel)]="searchString" autocomplete="off">
          </div>
          <button
              class="add-btn"
              (click)="openNoteDialog()"
            > + <span fxHide.xs>Add Notebook</span>
          </button>
      </div>

      <div class="not-found" *ngIf="(type!='own' && type!='shared' && deadManSwitchContainers[containerIndex]?.decryptedNotebooks.length===0) || (type=='shared' && sharedContainers[containerIndex]?.decryptedNotebooks.length===0) || (type=='own' && ownContainers[containerIndex]?.decryptedNotebooks.length===0) ">
        No Notebooks to display 
      </div>

      <div
        *ngIf="type=='own'"
        fxLayout="row wrap"
        fxLayoutAlign="start stretch"
        fxLayoutGap.xl="35px grid"
        fxLayoutGap.lg="30px grid"
        fxLayoutGap.md="25px grid"
        fxLayoutGap.sm="20px grid"
        fxLayoutGap.xs="15px grid"
        >
          <div
            fxFlex.xl="25%"
            fxFlex.lg="33%"
            fxFlex.md="50%"
            fxFlex.sm="50%"
            fxFlex.xs="100%"
            *ngFor="let notebook of ownContainers[containerIndex]?.decryptedNotebooks | search : searchString; let i=index;"
          >
          <app-notebooks-card [notebook]="notebook" [type]="type" [index]="containerIndex" [notebookIndex]="i"></app-notebooks-card>
        </div>
      </div>
      <div
          *ngIf="type=='shared'"
          fxLayout="row wrap"
          fxLayoutAlign="start stretch"
          fxLayoutGap.xl="35px grid"
          fxLayoutGap.lg="30px grid"
          fxLayoutGap.md="25px grid"
          fxLayoutGap.sm="20px grid"
          fxLayoutGap.xs="15px grid"
        >
          <div
            fxFlex.xl="20%"
            fxFlex.lg="25%"
            fxFlex.md="33%"
            fxFlex.sm="50%"
            fxFlex.xs="100%"
            *ngFor="let notebook of sharedContainers[containerIndex]?.decryptedNotebooks | search : searchString; let i=index;"
          >
          <app-notebooks-card [notebook]="notebook" [type]="type" [index]="containerIndex" [notebookIndex]="i"></app-notebooks-card>
        </div>
      </div>
      <div
          *ngIf="type!='own' && type!='shared'"
          fxLayout="row wrap"
          fxLayoutAlign="start stretch"
          fxLayoutGap.xl="35px grid"
          fxLayoutGap.lg="30px grid"
          fxLayoutGap.md="25px grid"
          fxLayoutGap.sm="20px grid"
          fxLayoutGap.xs="15px grid"
        >
          <div
            fxFlex.xl="20%"
            fxFlex.lg="25%"
            fxFlex.md="33%"
            fxFlex.sm="50%"
            fxFlex.xs="100%"
            *ngFor="let notebook of deadManSwitchContainers[containerIndex]?.decryptedNotebooks | search : searchString; let i=index;"
          >
          <app-notebooks-card [notebook]="notebook" [type]="type" [index]="containerIndex" [notebookIndex]="i"></app-notebooks-card>
        </div>

      </div>

    </div>
  </div>

  <ng-template #noteDialog>
    <mat-dialog-content>
      <div class="dialog-content" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
          <div class="relative" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <img class="note-icon" [src]="preview" alt="container-icon">
            <input type="file" id="myfile" (change)="onIconChange($event)" accept="image/*"/>
            <span class="dialog-label underline">Add Icon</span>
          </div>
          <div
            class="relative full-width mt-3"
            fxLayout="column"
            fxLayoutGap="10px"
          >
            <label for="name" class="dialog-label">Name</label>
            <input
              [(ngModel)]="name"
              autocomplete="off"
              type="text"
              class="dialog-input"
              placeholder="Notebook Name"
            />
          </div>
        </div>

        <div
          class="mt-3"
          fxLayout="row"
          fxLayoutGap="15px"
          fxLayoutAlign="end center"
        >
          <button class="cancel-btn" (click)="closeNotebookDialog()" [disabled]="disabledButton">Cancel</button>
          <button class="save-btn" (click)="createNotebook()" [disabled]="disabledButton">Create</button>
        </div>
      </div>
    </mat-dialog-content>
  </ng-template>
