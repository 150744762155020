import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  show = false;
  disabled = false;
  password = '';
  confirmPassword = '';
  token = '';
  constructor(private router: Router, private _snackBar: MatSnackBar, private user: UserService) {
    this.token = window.location.pathname.slice(17);
  }

  ngOnInit(): void {
    // this should be called because of the inheritance from OnInit
  }

  navigateToLandingPage(){
    this.router.navigate(['./']);
  }

  openSnackBar(message: string) {
		let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  isPassword(){
    this.show = !this.show;
  }
  
  save(){
    if(this.password.trim()!==this.confirmPassword.trim()){
      this.openSnackBar("Passwords don't match!");

    }else if(this.password.trim().includes(' ') || this.confirmPassword.trim().includes(' ')){
      this.openSnackBar("Invalid Password!");
    }else{
      if(this.password.trim().length<8){
        this.openSnackBar("Password is too short!");
      }else{
        this.disabled = true;
        this.user.resetPassword(this.token, this.password.trim())
        .subscribe({
          next: (res: any)=>{
            this.disabled = false;
            this.openSnackBar('Password edited successfully!');
            this.router.navigate(['./login', 0]);  
          },
          error: (error: any)=>{
            this.disabled = false;
            if(error.status==403) this.openSnackBar('You should enter a different password from the last one you have!');
            else if(error.status==404) this.openSnackBar('Wrong token!');
            else if(error.status==400) this.openSnackBar('Token missing or password missing!');
            else this.openSnackBar('There is some problem, please check the password entered!');
          }
        });
      }
    }
  }

}
