<div class="stepper-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px" >
    <p class="title">Access For Recipients</p>
    <div class="stepper-content" >
        <mat-stepper [orientation]="isMobile ? 'vertical' : 'horizontal'" #stepper>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Recipients' access way
                    </div>
                </ng-template>
                <div class="content">
                    <app-assignment-type [(recipientsEncryptionMode)]="recipientsEncryptionMode"></app-assignment-type>
                    <div
                        fxLayout.xs="column"
                        fxLayout="row"
                        fxLayoutAlign.xs="center center"
                        fxLayoutAlign="end center"
                        class="full-width-button other-content"
                        >
                        <button
                            class="full-width-button cancel-stepper"
                            (click)="cancel()"
                        >
                            Cancel
                        </button>
                        <button
                            matStepperNext
                            class="full-width-button next-stepper"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>
                    <div class="stepper-label">
                        Recipients' access tool
                    </div>
                </ng-template>
                <div class="content">
                    <app-add-hardwarekey-for-recipient *ngIf="recipientsEncryptionMode === 'hardwareKey'" [securityKey]="securityKey" [data]="data" (backStep)="stepper.previous()"></app-add-hardwarekey-for-recipient>
                    <app-add-passphrase-for-recipient *ngIf="recipientsEncryptionMode === 'passphrase'" [securityKey]="securityKey" [data]="data" (backStep)="stepper.previous()"></app-add-passphrase-for-recipient >
                    <app-pick-question  *ngIf="recipientsEncryptionMode === 'questionAnswer'" [securityKey]="securityKey" [data]="data" (backStep)="stepper.previous()"></app-pick-question>
                </div>
            </mat-step>
        </mat-stepper>
</div>
