import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/localstorage/localstorage.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { UserService } from 'src/app/services/user/user.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from '@angular/platform-browser';
import { ActivityLogsService } from 'src/app/services/activity-logs/activity-logs.service';
import { DexieService } from 'src/app/services/dexie/dexie.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-customer-support-page',
  templateUrl: './customer-support-page.component.html',
  styleUrls: ['./customer-support-page.component.scss', '../contact-us-page/contact-us-page.component.scss']
})
export class CustomerSupportPageComponent implements OnInit {

  @ViewChild('drawer') public sidenav: MatSidenav;
  captchaVerified = false;
  siteKey = environment.recaptchaSiteKey;
  isLogged = false;
  disabled = false;
  error = {firstName: false, lastName: false, phoneNumber: false, email: false, description: false, product: false, productVersion: false};
  supportForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    description: new FormControl('', [Validators.required]),
    product: new FormControl('', [Validators.required]),
    productVersion: new FormControl('', [Validators.required]),
	});
  constructor(private router: Router, private localstorage: LocalStorageService, private sidenavService: SidenavService, private user: UserService, private _snackBar: MatSnackBar, private meta: Meta, private titleService: Title, private authService: AuthService, private logsService: ActivityLogsService, private dexieService: DexieService) { 
    const token = this.localstorage.getToken();
    const userData = JSON.parse(this.localstorage.getUser());
    if(token){ 
      const tokenDecode = JSON.parse(atob(token.split('.')[1]));
      if(!this._tokenExpired(tokenDecode.exp)){
        this.isLogged = true;
      }else{
        this.authService.refreshToken(userData?.id, token).subscribe({
          next: (result: any) =>{
            this.localstorage.setToken(result.token);
            this.localstorage.setSessionTimeout((new Date(Date.now() + (60 * 60 * 1000))).toString());
          },
          error: (err: HttpErrorResponse)=> {
            this.dexieService.clearDB().then(() => {
              this.logsService.logLogout(userData?.id).subscribe({
                next: (response) => {
                  this.isLogged = false;
                  this._cleanlocalstorage();                  
                },
                error: (error) => {
                  console.error('Error logging logout', error);
                }
              });
            });
          }
        });
      }
    }else{
        this.isLogged = false;
        this._cleanlocalstorage();  
    }
  }

  ngOnInit(): void {
    // just shhould be called
    this.titleService.setTitle('SyFly Customer Support');
    this.meta.updateTag({ name: 'description', content: "We're Here to Help! Find answers to your questions and get the support you need with SyFly's Customer Support." });
    this.meta.updateTag({ property: 'og:title', content: 'SyFly Customer Support' });
    this.meta.updateTag({ property: 'og:description', content: "We're Here to Help! Find answers to your questions and get the support you need with SyFly's Customer Support." });
  }

  resetForm() {
  this.error = {firstName: false, lastName: false, phoneNumber: false, email: false, description: false, product: false, productVersion: false};
  this.supportForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      description: new FormControl('', [Validators.required]),
      product: new FormControl('', [Validators.required]),
      productVersion: new FormControl('', [Validators.required]),
    });

    setTimeout(() => {
      this.navigateToLandingPage();
    }, 600);
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  private _tokenExpired(expiration: any): boolean{
    return Math.floor(new Date().getTime()/1000) >= expiration;
  }

  private _cleanlocalstorage(){
    this.localstorage.clearLocalStorage();
  }

  async navigateToLandingPage(){
    await this.router.navigate(['./']);
  }

  async navigateToLoginPage(){
    await this.router.navigate(['./login', 0]);
  }

  async navigateToDashboard(){
    await this.router.navigate(['./home']);
  }

  async navigateToContactUs(){
    await this.router.navigate(['./contact-us']);
  }

  async navigateToCustomerSupport(){
    await this.router.navigate(['./customer-support']);
  }

  navigateToTutorials(){
    this.router.navigate(['./tutorials']);
  }
  
  navigateToDownloadPage(){
    this.router.navigate(['./download']);
  }

  async toggleSidenav(){
    await this.sidenav.toggle();
  }

  openSnackBar(message: string) {
    let snackBarRef = this._snackBar.open(message, 'Ok', {horizontalPosition: 'center', verticalPosition: 'top', duration: 5000});
    snackBarRef.onAction().subscribe(()=> this._snackBar.dismiss());
	}

  send(){
    if(this.supportForm.controls.firstName.errors?.['required']){
      this.error.firstName = true;
    }
    
    if(this.supportForm.controls.lastName.errors?.['required']){
      this.error.lastName = true;
    }
    
    if(this.supportForm.controls.email.errors?.['required']){
      this.error.email = true;
    }

    if(this.supportForm.controls.email.errors?.['email']){
      this.error.email = true;
    }
    
    if(this.supportForm.controls.phoneNumber.errors?.['required']){
      this.error.phoneNumber = true;
    }
    
    if(this.supportForm.controls.description.errors?.['required']){
      this.error.description = true;
    }
    
    if(this.supportForm.controls.product.errors?.['required']){
      this.error.product = true;
    }
    
    if(this.supportForm.controls.productVersion.errors?.['required']){
      this.error.productVersion = true;
    }

    if(!this.supportForm.controls.firstName.errors?.['required'] 
      && !this.supportForm.controls.lastName.errors?.['required']
      && !this.supportForm.controls.email.errors?.['required']
      && !this.supportForm.controls.email.errors?.['email']
      && !this.supportForm.controls.phoneNumber.errors?.['required']
      && !this.supportForm.controls.description.errors?.['required']
      && !this.supportForm.controls.product.errors?.['required']
      && !this.supportForm.controls.productVersion.errors?.['required']
      && this.captchaVerified){
        let data = {firstName: this.supportForm.controls.firstName.value, lastName: this.supportForm.controls.lastName.value, email: this.supportForm.controls.email.value, phoneNumber:this.supportForm.controls.phoneNumber.value, product: this.supportForm.controls.product.value, version: this.supportForm.controls.productVersion.value, description: this.supportForm.controls.description.value};
        this.user.sendCustomerSupport(data)
          .subscribe((res: any)=>{
              this.openSnackBar('Your request sent successfully!');
              this.disabled  = false;
          }); 
    }
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }

  resolved(captchaResponse: string) {
    this.captchaVerified = true;
  }

  onError(errorDetails: RecaptchaErrorParameters): void {
    this.captchaVerified = false;
  }

}
